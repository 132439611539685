import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";

import WidgetsLoader from "@components/WidgetsLoader";

import AppLayout from "./AppLayout";
import ViewFile from "@pages/Dox/ViewFile";
// import SPlans from "@widgets/Dox/SubscriptionPlans/SPlans";

// pages
// const DashboardA = lazy(() => import("@widgets/Dox/DashboardA"));
const Dashboard = lazy(() => import("@widgets/Dox/NewDashboard"));

const TrainingInvoice = lazy(() => import("@pages/Dox/Training/Invoice"));
const TrainingBillOfLading = lazy(() =>
  import("@pages/Dox/Training/BillOfLading")
);
const TrainingPurchaseOrder = lazy(() =>
  import("@pages/Dox/Training/PurchaseOrder")
);
const TrainingPassport = lazy(() => import("@pages/Dox/Training/Passport"));
const TrainingDrivingLicense = lazy(() =>
  import("@pages/Dox/Training/DrivingLicense")
);
const TrainingKyc = lazy(() => import("@pages/Dox/Training/Kyc"));
// const Settings = lazy(() => import("@pages/Dox/Settings"));
const Settings = lazy(() => import("@pages/Dox/NewSetting"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const Documents = lazy(() => import("@pages/Documents"));
const Faq = lazy(() => import("@pages/Dox/Faq"));
const Workflow = lazy(() => import("@pages/Dox/Workflow/Workflow"));
const SPlans = lazy(() => import("@pages/Dox/SubscriptionPlans/index"));
const CheckOut = lazy(() => import("@pages/Dox/SubscriptionPlans/checkout"));

const DashboardRoute = () => {
  return (
    <Suspense fallback={<WidgetsLoader />}>
      <Routes>
        <Route path="/*" element={<AppLayout />}>
          <Route index element={<Navigate to="/dashboard/home" />} />
          {/* <Route path="home" element={<DashboardA />} /> */}
          <Route path="home" element={<Dashboard />} />
          <Route path="documents/:docType" element={<Documents />} />

          <Route path="training/*">
            <Route path="invoice" element={<TrainingInvoice />} />
            <Route path="billOfLading" element={<TrainingBillOfLading />} />
            <Route path="purchaseOrder" element={<TrainingPurchaseOrder />} />
            <Route path="passport" element={<TrainingPassport />} />
            <Route path="drivingLicense" element={<TrainingDrivingLicense />} />
            <Route path="kyc" element={<TrainingKyc />} />
            <Route
              path="*"
              element={<Navigate to="/dashboard/404" replace />}
            />
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="faq" element={<Faq />} />
          <Route path="workflow" element={<Workflow />} />
          <Route path="subscription" element={<SPlans />} />
          <Route path="checkout" element={<CheckOut />} />
          <Route path="404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/dashboard/404" replace />} />
        </Route>
        <Route path="/view-file" element={<ViewFile />} />
      </Routes>
    </Suspense>
  );
};

export default DashboardRoute;
