import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts/AuthProvider";

import styled from "styled-components/macro";
import VerticalTrapezoidalMenu from "./Trapezoid";
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes,
  shadow,
} from "@styles/vars";
import SettingIcon from "@fonts/menu_icons/settingIcon";
import TrainingIcon from "@fonts/menu_icons/trainingIcon";
import DocumentIcon from "@fonts/menu_icons/documentIcon";
import SubscriptionIcon from "@fonts/menu_icons/subscriptionIcon";
import AccountIcon from "@fonts/menu_icons/accountIcon";
import ReviewIcon from "@fonts/menu_icons/reviewIcon";
import DashboardIcon from "@fonts/menu_icons/dashboardIcon";
import FaqIcon from "@fonts/menu_icons/faqIcon";

const MainContainer = styled.div`
  /* width: 80px; */
  /* height: 600px; */
  height: 100vh;

  /* height: 693px; */
  /* height: 600px; */
  /* background-color: #141426; */
  background-color: green;
  ${flex.row}
  align-items:start;
  justify-content: end;
`;

const MenuContainer = styled.div`
  width: 80px;
  /* height: 600px; */
  height: 100vh;
  /* height: calc(100% + 1px); */
  position: static;
  /* border: 2px solid red; */
  ${flex.col}
  justify-content: space-evenly;
  background-color: #141426;
  background-color: cyan;
`;

const Menu = styled(Link)`
  text-align: center;
  scale: 1.5;

  p {
    font-size: 7px;
    color: white;
  }
`;

const Menubar = () => {
  const [activeState, setActiveState] = useState("");
  const auth = useAuth();

  const handleMenuClick = (menuName) => {
    setActiveState(menuName);
  };
  // console.log(auth.isSuperAdmin());
  // {!auth.isSuperAdmin() ? (
  //   <Content data={menu} />
  // ) : (
  //   <Content data={isShowMenu1 ? superAdminMenu1 : superAdminMenu2} />
  // )}
  return (
    <MainContainer>
      {/* <VerticalTrapezoidalMenu /> */}
      {!auth.isSuperAdmin() ? (
        <MenuContainer>
          <Menu
            to="/dashboard/home"
            onClick={() => handleMenuClick("dashboard")}
          >
            <DashboardIcon
              className="menuIcon"
              active={activeState === "dashboard" ? true : false}
            />
            {/* <p>Dashboard</p> */}
          </Menu>
          <Menu
            to="/dashboard/documents/Invoice"
            onClick={() => handleMenuClick("invoice")}
          >
            <DocumentIcon
              className="menuIcon"
              active={activeState === "invoice" ? true : false}
            />
            {/* <p>Document</p> */}
          </Menu>
          <Menu to="/dashboard/faq" onClick={() => handleMenuClick("faq")}>
            <FaqIcon
              className="menuIcon"
              active={activeState === "faq" ? true : false}
            />
            {/* <p>Help</p> */}
          </Menu>
          <Menu
            to="/dashboard/subscription"
            onClick={() => handleMenuClick("subscription")}
          >
            <SubscriptionIcon
              className="menuIcon"
              active={activeState === "subscription" ? true : false}
            />
            {/* <p>Subscription</p> */}
          </Menu>
          <Menu
            to="/dashboard/settings"
            onClick={() => handleMenuClick("settings")}
          >
            <SettingIcon
              className="menuIcon"
              active={activeState === "settings" ? true : false}
            />
            {/* <p>Settings</p> */}
          </Menu>
        </MenuContainer>
      ) : (
        <MenuContainer>
          {" "}
          <Menu
            to="/superAdmin/superAdminDashboard"
            onClick={() => handleMenuClick("dashboard")}
          >
            <DashboardIcon
              className="menuIcon"
              active={activeState === "dashboard" ? true : false}
            />
            {/* <p>Dashboard</p> */}
          </Menu>
          <Menu
            to="/superAdmin/account"
            onClick={() => handleMenuClick("account")}
          >
            <DocumentIcon
              className="menuIcon"
              active={activeState === "account" ? true : false}
            />
            {/* <p>Document</p> */}
          </Menu>
        </MenuContainer>
      )}
    </MainContainer>
  );
};

export default Menubar;
