import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "api";
import FileDownload from "js-file-download";

// styling
import {
  DetailsWrapper,
  SidebarWrapper,
  Content,
  InvoiceSection,
  Sidebar,
  Paging,
  TableSection,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableData,
} from "../style.js";

// material icons
import InfoIcon from "@mui/icons-material/Info";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

import Editable from "@ui/Dox/Editable/index.js";
import DoxBtn from "@ui/Dox/DoxBtn";
import WidgetsLoader from "@components/WidgetsLoader";
import { useAuth } from "@contexts/AuthProvider";
import { nanoid } from "nanoid";
import CanvasImage from "../CanvasImage.jsx";
import instance from "@utils/instance.js";
import DoxIconBtn from "@ui/Dox/DoxIconBtn/index.jsx";
import DownloadIcon from "@ui/Dox/DownloadIcon/index.jsx";
import { useInterfaceContext } from "@contexts/interfaceContext.js";
import EditableMultiline from "@ui/Dox/EditableMultiline/index.js";
import CustomSelectBox from "@ui/Dox/CustomSelectBox/index.jsx";
import CustomSearchDropdown from "@ui/Dox/CustomSearchDropdown/index.jsx";
import CustomDropDownPopUp from "@ui/Dox/CustomDropDownPopup/index.jsx";
import Swal from "sweetalert2";

const InvoiceDetails = ({ state }) => {
  const [data, setData] = useState({});
  const [customSection, setCustomSection] = useState({
    buyer: "",
    seller: "",
    others: "",
    transaction: "",
  });
  const [moderated, setModerated] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pagesLength, setPagesLength] = useState(1);
  const [pageDetails, setPageDetails] = useState([]);
  const [pageFields, setPageFields] = useState([]);
  const [newPageFields, setNewPageFields] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [fieldLabels, setFieldLabels] = useState([]);
  const [tableData, setTableData] = useState({});
  const [buyerDetails, setBuyerDetails] = useState([]);
  const [sellerDetails, setSellerDetails] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [othersDetails, setOthersDetails] = useState([]);
  const [tableDetails, setTableDetails] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [allInvoiceLabels, setAllInvoiceLabels] = useState([]);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [showAddRowBtn, setShowAddRowBtn] = useState(false);
  const [showDeleteRowBtn, setShowDeleteRowBtn] = useState(false);
  const [tableRowInfo, setTableRowInfo] = useState({
    maxRow: [],
  });

  const [buyerName, setBuyerName] = useState("");
  const [tableItems, setTableItems] = useState([]);
  const [tableAmount, setTableAmount] = useState([]);
  const [isShowQB, setIsShowQB] = useState(false);
  const [open, setOpen] = useState(false);
  const [categorizedFields, setCategorizedFields] = useState([]);
  // const [newFields, setNewFields] = useState({});
  // const [isChanged, setIsChanged] = useState(false);
  // const [isShowField, setIsShowField] = useState(false);
  // const fieldNameRef = useRef("");
  // const fieldValueRef = useRef("");
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const {
    notification,
    setNotification,
    showIntegratedApps,
    qbItems,
    setQBItems,
    qbCustomer,
    setQBCustomer,
  } = useInterfaceContext();
  const { payload, config, ext } = state;

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(500);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right -
            mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  useEffect(() => {
    // pointerEvents works for both mouse and touch
    window.addEventListener("pointermove", resize);
    window.addEventListener("pointerup", stopResizing);
    return () => {
      window.removeEventListener("pointermove", resize);
      window.removeEventListener("pointerup", stopResizing);
    };
  }, [resize, stopResizing]);

  const body = {
    companyId: payload.companyId,
    documentId: payload.documentId,
    documentType: payload.documentSubType,
  };

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const updateField = (e, id, isTable = false) => {
    let key = "";

    if (e.target.id === "buyer") {
      key = "buyer";
    } else if (e.target.id === "seller") {
      key = "seller";
    } else if (e.target.id === "others") {
      key = "others";
    } else if (e.target.id === "transaction") {
      key = "transaction";
    }

    if (e.key === "Enter" || e.type === "blur") {
      let updatedFields = [];
      let updatedTableFields = [];
      let newFields = [];

      if (isTable) {
        const tableCells = tableData.cells.map((cell) =>
          cell.id === id ? { ...cell, text: e.target.value } : cell
        );
        updatedTableFields = {
          ...tableData,
          cells: tableCells,
        };

        setTableData(updatedTableFields);
      } else {

        if (allDetails) {
          updatedFields = allDetails.map((ele) =>
            ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
          );
        }
        setAllDetails(updatedFields);
        console.log("buyerDetails", buyerDetails);
        if (pageDetails && key !== "") {
          console.log("buyer")
          if (key === "buyer") {
            newFields = buyerDetails.map((ele) =>
              ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
            );
          } else if (key === "seller") {
            newFields = sellerDetails.map((ele) =>
              ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
            );
          } else if (key === "others") {
            newFields = othersDetails.map((ele) =>
              ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
            );
          } else if (key === "transaction") {
            newFields = transactionDetails.map((ele) =>
              ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
            );
          }
        }
      }
      if (isTable) {
        const allObj = allDetails.filter((ele) => ele.label !== "table");
        updatedFields = [...allObj, updatedTableFields];
      } else {
        const allObj = updatedFields.filter((ele) => ele.label !== "table");
        // updatedFields = [...allObj, tableData];
        if (tableData.tableHeaders === "") {
          updatedFields = [...allObj];
        } else {
          updatedFields = [...allObj, tableData];
        }
      }

      const currentPageInfo = data.Pages.filter(
        (ele) => ele.pageNo === pageNumber
      )[0];
      currentPageInfo.moderated = updatedFields;

      if (key === "buyer") {
        setBuyerDetails(newFields);
      } else if (key === "seller") {
        setSellerDetails(newFields);
      } else if (key === "others") {
        setOthersDetails(newFields);
      } else if (key === "transaction") {
        setTransactionDetails(newFields);
      }

      // get fields of all pages to updateDocuments as moderated
      let moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { id: page._id, moderated: page.moderated }
          : { id: page._id, moderated: page.prediction }
      );

      // setAllDetails(updatedFields)
      setPageFields(updatedFields);
      setModerated(moderatedFields);
      if (isTable) {
        setCurrentTableData(updatedTableFields);
      }
    }
  };

  const updateByBox = (e, obj, previousObj, isResized = false) => {
    const deleteObjectFromArray = (array, objectToDelete) => {
      const deletedArray = array.filter((obj) => obj !== objectToDelete);
      return deletedArray;
    };
    if (obj.label != previousObj.label) {
      setBuyerDetails(deleteObjectFromArray(buyerDetails, previousObj));
      setSellerDetails(deleteObjectFromArray(sellerDetails, previousObj));
      setTransactionDetails(
        deleteObjectFromArray(transactionDetails, previousObj)
      );
      setOthersDetails(deleteObjectFromArray(othersDetails, previousObj));
    }

    if (isResized && !pageFields.find((ele) => ele.id === obj.id)) {
      return;
    }

    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      const tableCells = tableData.cells.map((cell) =>
        cell.id === obj.id ? { ...cell, ...obj } : cell
      );
      const headerCells = tableData.tableHeaders.map((cell) =>
        cell.id === obj.id || (cell.col === cell.col && cell.row === "1")
          ? { ...cell, ...obj }
          : cell
      );
      updatedTableFields = {
        ...tableData,
        cells: tableCells,
        tableHeaders: headerCells,
      };
      // setTableData(updatedTableFields);
    } else if (obj.type || obj.isNew === true) {
      if (obj.isNew === true) {
        let newLabel = allDetails.some((item) => item.id === obj.id);
        //add new label by box
        if (!newLabel) {
          allInvoiceLabels.map((item) => {
            if (item.key === obj.label) {
              if (item.group === "buyer") {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                buyerDetails.push(obj);
                setBuyerDetails(buyerDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setAllDetails(updatedFields);
              } else if (item.group === "seller") {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                sellerDetails.push(obj);
                setSellerDetails(sellerDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setAllDetails(updatedFields);
              } else if (item.group === "others") {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                othersDetails.push(obj);
                setOthersDetails(othersDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setOthersDetails(updatedFields);
              } else if (item.group === "transaction") {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                transactionDetails.push(obj);
                setTransactionDetails(transactionDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setTransactionDetails(updatedFields);
              } else {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                othersDetails.push(obj);
                setOthersDetails(othersDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setOthersDetails(updatedFields);
              }
            }
          });
        }
      }

      updatedFields = allDetails.map((ele) =>
        ele.id === obj.id ? { ...ele, ...obj } : ele
      );

      setAllDetails(updatedFields);
      if (pageFields) {
        allInvoiceLabels.map((item) => {
          if (item.key === obj.label) {
            //function for update and switch group label
            const updateGroupLabel = (arr, setGroup) => {
              const ind = arr.findIndex((item) => item.id === obj.id);
              if (ind !== -1) {
                const updatedDetails = [...arr];
                updatedDetails[ind] = obj;
                setGroup(updatedDetails);
              } else {
                const updatedArr = [...arr, obj];
                setGroup(updatedArr);
              }
            };

            if (item.group === "buyer") {
              updateGroupLabel(buyerDetails, setBuyerDetails);
            } else if (item.group === "seller") {
              updateGroupLabel(sellerDetails, setSellerDetails);
            } else if (item.group === "others") {
              updateGroupLabel(othersDetails, setOthersDetails);
            } else if (item.group === "transaction") {
              updateGroupLabel(transactionDetails, setTransactionDetails);
            }
          }
        });
      }
    } else {
      updatedFields = [
        ...pageFields,
        { ...obj, page_no: pageNumber, type: "field" },
      ];
    }
    if (obj.row) {
      const allObj = allDetails.filter((ele) => ele.label !== "table");
      updatedFields = [...allObj, updatedTableFields];
    } else {
      const allObj = updatedFields.filter((ele) => ele.label !== "table");
      // updatedFields = [...allObj, tableData];
      if (tableData.tableHeaders === "") {
        updatedFields = [...allObj];
      } else {
        updatedFields = [...allObj, tableData];
      }
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedTableFields);
    }
  };

  const deleteByBox = (e, obj) => {
    // if (!pageFields.find((ele) => ele.id === obj.id)) {
    //   return;
    // }
    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      // const tableCells = tableData.cells.filter((cell) => cell.id !== obj.id);

      updatedTableFields = {
        ...tableData,
        // cells: tableCells,
      };
    } else if (obj.type || obj.isNew === true) {
      updatedFields = allDetails.filter((ele) => ele.id !== obj.id);
      setAllDetails(updatedFields);
      if (pageFields) {
        const updatedBuyerDetails = buyerDetails.filter(
          (ele) => ele.id !== obj.id
        );
        const updatedSellerDetails = sellerDetails.filter(
          (ele) => ele.id !== obj.id
        );
        const updatedOthersDetails = othersDetails.filter(
          (ele) => ele.id !== obj.id
        );
        const updatedTransactionDetails = transactionDetails.filter(
          (ele) => ele.id !== obj.id
        );
        setBuyerDetails(updatedBuyerDetails);
        setSellerDetails(updatedSellerDetails);
        setOthersDetails(updatedOthersDetails);
        setTransactionDetails(updatedTransactionDetails);
      }
    }

    if (obj.row) {
      const allObj = allDetails.filter(
        (ele) => ele.label !== "table" && ele.id !== obj.id
      );
      updatedFields = [...allObj, updatedTableFields];
    } else {
      const allObj = updatedFields.filter((ele) => ele.label !== "table");
      // updatedFields = [...allObj, tableData];
      if (tableData.tableHeaders === "") {
        updatedFields = [...allObj];
      } else {
        updatedFields = [...allObj, tableData];
      }
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedTableFields);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const submitHandler = async () => {
    let obj = {
      _id: data._id,
      documentType: body.documentType,
      moderatedFields: moderated,
    };
    const noOfRows =
      tableRowInfo.maxRow && tableRowInfo.maxRow.map((rowNumber) => rowNumber);
    let qbData = {
      items: qbItems && qbItems,
      customer: qbCustomer && qbCustomer,
    };
    if (moderated.length === 0) {
      // get fields of all pages to updateDocuments as moderated
      data.Pages.map((page) => {
        if (page.moderated.length > 0) {
          const allGroupsData =
            page &&
            page.moderated.reduce((accumulator, currentGroup) => {
              // Extract the data array from each group and concatenate it to the accumulator
              const groupData = Object.values(currentGroup)[0].data;
              return accumulator.concat(groupData);
            }, []);

          // obj.moderatedFields = { id: page._id, moderated: page.moderated };
          obj.moderatedFields = [
            { id: page._id, moderated: [...allDetails, tableData] },
          ];
        } else {
          obj.moderatedFields = [
            { id: page._id, moderated: [...allDetails, tableData] },
          ];
        }
      });
    }

    if (
      noOfRows.length === qbItems.length &&
      Object.keys(qbCustomer).length > 0 &&
      qbItems.length > 0
    ) {
      obj = {
        ...obj,
        quickBooksOnline: qbData,
      };

      try {
        setIsLoading(true);
        const res = await api.post(
          "/document/updateDocumentData",
          { ...obj },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );

        // set notification for new file uploaded
        const oldNotify = notification.isMarkAsRead
          ? notification.notifications.slice(0, 5)
          : notification.notifications;
        const notifications = [
          {
            id: nanoid(),
            type: body.documentType,
            msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
            tab: "Reviewed",
          },
          ...oldNotify,
        ];

        setNotification({
          isMarkAsRead: false,
          notifications: notifications,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);

        obj = {
          ...obj,
          quickBooksOnline: {},
        };
      }
      goBack();
    } else {
      Swal.fire({
        title: "Are you sure ?",
        text: "All QuickBooks fields are not linked !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // confirmButtonText: "Yes, Submit !",
        confirmButtonText: "Submit , anyway !",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Submitted!", "Your file has been Submitted.", "success");
          obj = {
            ...obj,
            quickBooksOnline: {},
          };
          //  remove s from url before code push
          try {
            setIsLoading(true);
            const res = api.post(
              "/document/updateDocumentData",
              { ...obj },
              {
                headers: {
                  "x-access-token": auth.user.accessToken,
                },
              }
            );
            // set notification for new file uploaded
            const oldNotify = notification.isMarkAsRead
              ? notification.notifications.slice(0, 5)
              : notification.notifications;
            const notifications = [
              {
                id: nanoid(),
                type: body.documentType,
                msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
                tab: "Reviewed",
              },
              ...oldNotify,
            ];

            setNotification({
              isMarkAsRead: false,
              notifications: notifications,
            });
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoading(false);
            obj = {
              ...obj,
              quickBooksOnline: {},
            };
          }
          goBack();
        }
      });
    }

  

    //   // set notification for new file uploaded
    //   const oldNotify = notification.isMarkAsRead
    //     ? notification.notifications.slice(0, 5)
    //     : notification.notifications;
    //   const notifications = [
    //     {
    //       id: nanoid(),
    //       type: body.documentType,
    //       msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
    //       tab: "Reviewed",
    //     },
    //     ...oldNotify,
    //   ];

    //   setNotification({
    //     isMarkAsRead: false,
    //     notifications: notifications,
    //   });
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   setIsLoading(false);
    //   // console.log(qbCustomer);
    //   // console.log(qbItems);
    //   obj = {
    //     ...obj,
    //     quickBooksOnline: {},
    //   };
    // }
    // goBack();
    // console.log("submit", obj);

    // if (
    //   noOfRows.length === qbItems.length &&
    //   Object.keys(qbCustomer).length > 0 &&
    //   qbItems.length > 0
    // ) {
    //   setTimeout(() => {
    //     goBack();
    //   }, 500);
    // }
  };



  const getDetails = async () => {
    let fileInfo;
    try {
      setIsLoading(true);
      const res = await instance.post(
        "/document/showDocumentDetails",
        body,
        config
      );
      fileInfo = res.data;
      const allLabels = res.data.invoiceLabels;
      const pageData = res.data.result;
      const allFields = pageData.Pages[0].prediction;
      console.log("---------->>>", pageData.Pages);
      console.log("allFields---------->>>", allFields);
      if (allFields) {
        let buyerSection = "";
        let sellerSection = "";
        let othersSection = "";
        let transactionSection = "";
        allFields.map((field) => {
          if (field.hasOwnProperty("buyer")) {
            buyerSection = field.buyer.groupVal;
          } else if (field.hasOwnProperty("seller")) {
            sellerSection = field.seller.groupVal;
          } else if (field.hasOwnProperty("others")) {
            othersSection = field.others.groupVal;
          } else if (field.hasOwnProperty("transaction")) {
            transactionSection = field.transaction.groupVal;
          }
        });
        setCustomSection({
          buyer: buyerSection,
          seller: sellerSection,
          others: othersSection,
          transaction: transactionSection,
        });
      }

      setData(pageData);
      setAllInvoiceLabels(allLabels);
      setPagesLength(pageData.Pages.length);
      getCurrentPageDetails(pageData);
      getHeaders();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getHeaders = async () => {
    try {
      setIsLoading(true);
      const res = await instance.get("/dashboard/getLabels", {}, config);
      const headerLabels = res.data.data.invoiceTables;
      const feildLabels = res.data.data.invoiceLabels;
      setFieldLabels(feildLabels);
      setTableHeaders(headerLabels);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  // get csv file
  const getCsv = async () => {
    const URL = `${process.env.REACT_APP_BASE_URL}/document/downloadExtractedCSV`;
    // download CSV api call
    instance({
      url: URL,
      method: "post",
      responseType: "blob",
      headers: { "x-access-token": auth.user.accessToken },
      data: {
        documentIds: [payload.documentId],
        documentType: payload.documentSubType,
      },
    })
      .then((resp) => {
        FileDownload(resp.data, "Extracted.csv");
      })
      .catch((e) => console.log(e));
  };

  const getCurrentPageDetails = (data) => {
    console.log("pageNumber", pageNumber);
    const page =
      data.Pages && data.Pages.filter((ele) => ele.pageNo === pageNumber)[0];
    setPageDetails(page);
    console.log(page);
    const fields =
      page && page.moderated.length > 0
        ? page && page.moderated
        : page && page.prediction;
    console.log("fields", fields);
    setPageFields(fields);

    // const allGroupFields = [];
    // fields &&
    //   fields.forEach((group) => {
    //     const groupData = group[Object.keys(group)[0]].data;
    //     allGroupFields.push(...groupData);
    //   });
    // console.log("allGroupFields", allGroupFields);
    // setNewPageFields(allGroupFields);

    if (fields) {
      fields.map((field) => {
        if (field.hasOwnProperty("buyer")) {
          console.log("buyer is persent")
          setBuyerDetails(field.buyer.data);
        } else if (field.hasOwnProperty("seller")) {
          setSellerDetails(field.seller.data);
        } else if (field.hasOwnProperty("others")) {
          setOthersDetails(field.others.data);
        } else if (field.hasOwnProperty("transaction")) {
          setTransactionDetails(field.transaction.data);
        } else if (field.hasOwnProperty("table")) {
          setTableDetails(field.table.data[0]);
        }
      });
    }

    setCurrentTableData(fields);
  };

  useEffect(() => {
    if (tableDetails.length > 0) {
      setAllDetails([
        ...buyerDetails,
        ...sellerDetails,
        ...othersDetails,
        ...transactionDetails,
        tableDetails,
      ]);
    } else {
      setAllDetails([
        ...buyerDetails,
        ...sellerDetails,
        ...othersDetails,
        ...transactionDetails,
      ]);
    }

    buyerDetails.map((ele) => {
      if (ele.label === "buyer_name") {
        setBuyerName(ele.ocr_text);
      }
    });
    // findBuyer(buyerDetails);
  }, [pageDetails]);

  useEffect(() => {
    buyerDetails.map((ele) => {
      if (ele.label === "buyer_name") {
        setBuyerName(ele.ocr_text);
      }
    });
  }, [buyerDetails]);

  const updateSectionHandler = async (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "buyer") {
        setCustomSection({
          ...customSection,
          buyer: e.target.value,
        });
      } else if (e.target.id === "seller") {
        setCustomSection({
          ...customSection,
          seller: e.target.value,
        });
      } else if (e.target.id === "others") {
        setCustomSection({
          ...customSection,
          others: e.target.value,
        });
      } else if (e.target.id === "transaction") {
        setCustomSection({
          ...customSection,
          transaction: e.target.value,
        });
      }

      try {
        const res = await instance.post(
          "/document/updateCustomSection",
          {
            LabelName: "invoiceLabels",
            currentGroupName: e.target.id,
            newGroupName: e.target.value,
          },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const changeHeaderHandler = (e) => {
    if (!auth.isAdmin()) {
      return;
    }
    const id = e.target.name;
    const selected = tableHeaders.filter(
      (ele) => ele.key === e.target.value
    )[0];

    const selectedCell = tableData.tableHeaders.filter(
      (ele) => ele.id === id
    )[0];

    let updatedFields = [];
    //   .filter((field) => field.label === "table")[0]
    const tableCells = tableData.cells.map((cell) =>
      cell.col === selectedCell.col
        ? { ...cell, aliasLabel: selected.value, label: selected.key }
        : cell
    );

    const headerCells = tableData.tableHeaders.map((cell) =>
      cell.col === selectedCell.col
        ? { ...cell, aliasLabel: selected.value, label: selected.key }
        : cell
    );
    updatedFields = {
      ...tableData,
      cells: tableCells,
      tableHeaders: headerCells,
    };

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    setCurrentTableData(updatedFields);
  };

  const setCurrentTableData = (fields) => {
    let tableInfo = "";
    if (fields && Array.isArray(fields)) {
      fields.map((field) => {
        if (field.hasOwnProperty("table")) {
          tableInfo = field.table.data[0];
        }
      });
    }

    const tableHeaders =
      tableInfo && tableInfo.cells.filter((cell) => cell.row === 1);
    // tableInfo && tableInfo.cells.filter((cell) => cell.row );
    if (Array.isArray(fields)) {
      setTableData({ ...tableInfo, tableHeaders: tableHeaders });
    } else {
      setTableData(fields);
    }
  };

  //function for delete selected table row
  const deleteTableRow = () => {
    const updatedTableData =
      tableData &&
      tableData.cells.filter((ele) => !selectedTableRows.includes(ele.row));

    const updatedItems = qbItems.filter(
      (item) => !selectedTableRows.includes(item.row)
    );
    setQBItems(updatedItems);

    updatedTableData.forEach((item) => {
      if (item.row > selectedTableRows[0]) {
        item.row -= 1;
      }
    });

    const updatedTableFields = {
      ...tableData,
      cells: updatedTableData,
    };

    const lastObj = updatedTableData[updatedTableData.length - 1];
    const isRowGreaterThanSelectedValues = selectedTableRows.every(
      (value) => lastObj.row > value
    );

    if (!isRowGreaterThanSelectedValues) {
      setSelectedTableRows([]);
    }

    const allObj = allDetails.filter((ele) => ele.label !== "table");
    let updatedFields = [...allObj, updatedTableFields];

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );
    setModerated(moderatedFields);
    setCurrentTableData(updatedTableFields);
  };

  //function for add only one selected table row
  const AddTableRow = () => {
    if (selectedTableRows.length === 1) {
      const newRowObjArr = tableData.cells.filter(
        (item) => item.row === selectedTableRows[0]
      );

      if (newRowObjArr.length > 0) {
        const min = 1000;
        const max = 9999;
        const randomFourDigitNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        const updatedObjects = newRowObjArr.map((item) => {
          return {
            ...item,
            row: item.row + 1,
            text: "",
            id: `${item.id}-${randomFourDigitNumber}`,
            verification_status: "edited",
            xmax: null,
            xmin: null,
            ymax: null,
            ymin: null,
          };
        });

        tableData.cells.map((item) => {
          if (item.row > selectedTableRows[0]) {
            item.row += 1;
          }
        });

        let ind = selectedTableRows[0] * updatedObjects.length;
        tableData.cells.splice(ind, 0, ...updatedObjects);

        const updatedTableFields = {
          ...tableData,
          cells: tableData.cells,
        };
        // setTableData(updatedTableFields);
        // setSelectedTableRows([]);

        const allObj = allDetails.filter((ele) => ele.label !== "table");
        let updatedFields = [...allObj, updatedTableFields];

        const currentPageInfo = data.Pages.filter(
          (ele) => ele.pageNo === pageNumber
        )[0];
        currentPageInfo.moderated = updatedFields;

        const moderatedFields = data.Pages.map((page) =>
          page.moderated.length > 0
            ? { id: page._id, moderated: page.moderated }
            : { id: page._id, moderated: page.prediction }
        );
        setModerated(moderatedFields);
        setCurrentTableData(updatedTableFields);
      }
    }
  };

  const handleCheckboxChange = (e, row) => {
    let updatedTableRows;
    if (e.target.checked) {
      updatedTableRows = [...selectedTableRows, row];
    } else {
      updatedTableRows = selectedTableRows.filter((ele) => ele !== row);
    }

    setSelectedTableRows(updatedTableRows);
    const numRows = updatedTableRows.length;
    setShowDeleteRowBtn(numRows > 0);
    setShowAddRowBtn(numRows > 0 && numRows < 2);
  };

  const getQuickBooksData = (buyerObj, itemsObj) => {
    if (buyerObj && Object.keys(buyerObj).length > 0) {
      if (moderated.length > 0) {
        let updateModerated = moderated[0].moderated.map((ele) =>
          ele.label === "buyer_name"
            ? { ...ele, ocr_text: buyerObj.customer }
            : ele
        );
        setModerated(updateModerated);
      }
      // const updatedBuyerDetails
      const updatedBuyerDetails = buyerDetails.map((ele) =>
        ele.label === "buyer_name"
          ? { ...ele, ocr_text: buyerObj.customer }
          : ele
      );
      setBuyerDetails(updatedBuyerDetails);
      const updatedAllDetails = allDetails.map((ele) =>
        ele.label === "buyer_name"
          ? { ...ele, ocr_text: buyerObj.customer }
          : ele
      );
      setAllDetails(updatedAllDetails);
    }
    if (itemsObj && Object.keys(itemsObj).length > 0) {
      const tableCells =
        tableData &&
        tableData.cells.map((cell) =>
          cell.row === itemsObj.row && cell.col === 1
            ? { ...cell, text: itemsObj.name }
            : cell
        );

      let updatedTableFields = {
        ...tableData,
        cells: tableCells,
      };
      setTableData(updatedTableFields);

      if (qbItems.length > 0) {
        // Find the index of the element with the matching row
        const rowIndex = qbItems.findIndex((ele) => ele.row === itemsObj.row);

        if (rowIndex !== -1) {
          // If the row is present, update the existing object
          setQBItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[rowIndex] = itemsObj;
            return updatedItems;
          });
        } else {
          // If the row is not present, push itemsObj to qbItems
          setQBItems((prevItems) => [...prevItems, itemsObj]);
        }
      } else {
        setQBItems([itemsObj]);
      }
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    getCurrentPageDetails(data);
  }, [pageNumber]);

  const previousHandler = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextHandler = () => {
    if (pageNumber < pagesLength - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    if (tableData) {
      const distinctRows = [
        ...new Set(tableData.cells && tableData.cells.map((item) => item.row)),
      ];
      setTableRowInfo({
        maxRow: distinctRows,
      });
    }
  }, [tableData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/integrations/", {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        });
        if (res.status == 200) {
          setIsShowQB(res.data.integrations.quickbooksOnline.isLinked);
        }
        // setCustomerList(res.data.Customer);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tableData && tableData.cells) {
      let tableItemsArr = tableData.cells.filter((ele) => ele.col === 1);
      setTableItems(tableItemsArr);
      let tableAmountArr = tableData.cells.filter(
        (ele) => ele.aliasLabel === "Line_Amount"
      );
      setTableAmount(tableAmountArr);
    }
  }, [tableData]);

  return (
    <DetailsWrapper>
      {/* {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Document Submitted Successfully!
          </Alert>
        </Snackbar>
      )} */}
      {!isLoading ? (
        <>
          <Content>
            <CanvasImage
              image={
                pageDetails &&
                pageDetails.images &&
                pageDetails.images.original_with_long_expiry
              }
              // image={pages.images.original && pages.images.original_with_long_expiry}
              info={[
                ...buyerDetails,
                ...sellerDetails,
                ...othersDetails,
                ...transactionDetails,
                tableData,
              ]}
              // tableInfo={[...tableDetails]}
              // tableInfo={[tableData]}
              resizeHandler={updateByBox}
              fieldlabels={fieldLabels.length > 0 && fieldLabels}
              tableHeaders={tableHeaders}
              updateHandler={updateByBox}
              deleteHandler={deleteByBox}
            />
          </Content>
          {/* resizer class is enable resize bar  */}
          <SidebarWrapper ref={sidebarRef} style={{ width: sidebarWidth }}>
            <div className="resizer" onPointerDown={startResizing} />
            <Sidebar style={{ display: "flex" }}>
              <InvoiceSection className="paging-section">
                <Paging style={{ position: "unset", fontSize: "14px" }}>
                  <span
                    className={`prev ${pageNumber > 0 ? "primary" : "disable"}`}
                    onClick={previousHandler}
                  >
                    <ArrowLeftIcon />
                  </span>
                  <span>Page {pageNumber + 1}</span>
                  <span
                    className={`next ${
                      pageNumber < pagesLength - 1 ? "primary" : "disable"
                    }`}
                    onClick={nextHandler}
                  >
                    <ArrowRightIcon />
                  </span>
                </Paging>
                <DoxIconBtn
                  icon="close"
                  iconStyles={{ margin: "0px", padding: "0px" }}
                  handler={goBack}
                />
              </InvoiceSection>
              <InvoiceSection style={{ paddingTop: "15px", height: "100%" }}>
                <div
                  className="title"
                  style={{ justifyContent: "flex-end", gap: "10px" }}
                >
                  {/* {showIntegratedApps.quickbooksOnline && (
                    <img src={QuickBookIcon} className="qb" alt="QuickBook" />
                  )} */}
                  {pageDetails.moderated &&
                    pageDetails.moderated.length > 0 && (
                      <DownloadIcon
                        className={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0
                            ? "download-csv"
                            : "disable"
                        }
                        handler={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0 &&
                          getCsv
                        }
                      />
                    )}
                </div>
                <form className="details">
                  {buyerDetails.length > 0 && (
                    <div className="title">
                      <span
                        style={{
                          display: "inline-flex",
                          textTransform: "capitalize",
                        }}
                      >
                        <Editable
                          text={customSection.buyer}
                          id="buyer"
                          handler={updateSectionHandler}
                          style={{
                            width: "100%",
                          }}
                          editable={auth.isAdmin()}
                        />
                      </span>
                    </div>
                  )}

                  {buyerDetails &&
                    buyerDetails.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              text={ele.ocr_text}
                              id="buyer"
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}

                  {sellerDetails.length > 0 && (
                    <div className="title">
                      <span
                        style={{
                          display: "inline-flex",
                          textTransform: "capitalize",
                        }}
                      >
                        <Editable
                          text={customSection.seller}
                          id="seller"
                          handler={updateSectionHandler}
                          style={{
                            width: "100%",
                            marginTop: "25px",
                          }}
                          editable={auth.isAdmin()}
                        />
                      </span>
                    </div>
                  )}

                  {sellerDetails &&
                    sellerDetails.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              id="seller"
                              text={ele.ocr_text}
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}

                  {othersDetails.length > 0 && (
                    <div className="title">
                      <span
                        style={{
                          display: "inline-flex",
                          textTransform: "capitalize",
                        }}
                      >
                        <Editable
                          text={customSection.others}
                          id="others"
                          handler={updateSectionHandler}
                          style={{
                            width: "100%",
                            marginTop: "25px",
                          }}
                          editable={auth.isAdmin()}
                        />
                      </span>
                    </div>
                  )}

                  {othersDetails &&
                    othersDetails.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              id="others"
                              text={ele.ocr_text}
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}

                  {transactionDetails.length > 0 && (
                    <div className="title">
                      <span
                        style={{
                          display: "inline-flex",
                          textTransform: "capitalize",
                        }}
                      >
                        <Editable
                          text={customSection.transaction}
                          id="transaction"
                          handler={updateSectionHandler}
                          style={{
                            width: "100%",
                            marginTop: "25px",
                          }}
                          editable={auth.isAdmin()}
                        />
                      </span>
                    </div>
                  )}
                  {transactionDetails &&
                    transactionDetails.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              id="transaction"
                              text={ele.ocr_text}
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}

                  {tableData.tableHeaders && (
                    <TableContainer>
                      <div className="title">
                        <span
                          style={{
                            display: "inline-flex",
                            marginLeft: "10px",
                          }}
                        >
                          Table
                        </span>
                        <DoxBtn
                          text="Add Row"
                          className={showAddRowBtn ? "" : "disable"}
                          handler={AddTableRow}
                          style={{
                            height: "30px",
                            margin: "18px 5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                        <DoxBtn
                          text="Delete Row"
                          className={showDeleteRowBtn ? "" : "disable"}
                          handler={deleteTableRow}
                          style={{
                            height: "30px",
                            margin: "18px 5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                      </div>
                      {/* <div
                        className="table"
                        style={{
                          gridTemplateColumns: `repeat(${
                            tableData.tableHeaders &&
                            tableData.tableHeaders.length + 1
                          }, 1fr)`,
                        }}
                      > */}

                      <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
                        <Table>
                          <thead>
                            <TableRow>
                              <TableHead></TableHead>
                              {isShowQB && buyerName && (
                                <TableHead className="qb">
                                  <p
                                    style={{
                                      border: "2px solid #cfd1d3",
                                      borderRadius: "6px",
                                      padding: "3px 10px",
                                      width: "130px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Quickbooks
                                  </p>
                                </TableHead>
                              )}

                              {tableData.tableHeaders &&
                                tableData.tableHeaders.map(
                                  (ele) =>
                                    ele.row === 1 && (
                                      <TableHead key={ele.id}>
                                        <CustomSelectBox
                                          className="info"
                                          selected={ele}
                                          items={tableHeaders}
                                          handler={changeHeaderHandler}
                                        />
                                      </TableHead>
                                    )
                                )}
                            </TableRow>
                          </thead>

                          <tbody>
                            {tableRowInfo.maxRow &&
                              tableRowInfo.maxRow.map((rowNumber, ind) => {
                                return (
                                  <TableRow key={rowNumber}>
                                    <TableData>
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        // checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, rowNumber)
                                        }
                                      />
                                    </TableData>
                                    {isShowQB && buyerName && (
                                      <TableData>
                                        <CustomDropDownPopUp
                                          tableItems={tableItems}
                                          tableAmount={tableAmount}
                                          row={rowNumber}
                                          showQB={isShowQB}
                                          buyerName={buyerName}
                                          handler={getQuickBooksData}
                                        />
                                      </TableData>
                                    )}

                                    {tableData.cells &&
                                      tableData.cells.map((ele) => {
                                        return (
                                          <>
                                            {ele.row === rowNumber ? (
                                              <TableData key={ele.id}>
                                                <EditableMultiline
                                                  className="info"
                                                  style={{
                                                    width: "150px",
                                                    display: "block",
                                                  }}
                                                  text={ele.text}
                                                  name={ele.label}
                                                  handler={(e) =>
                                                    updateField(e, ele.id, true)
                                                  }
                                                  editable={auth.isAdmin()}
                                                />
                                              </TableData>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                  </TableRow>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                      {/* {tableData.tableHeaders &&
                          tableData.tableHeaders.map(
                            (ele) =>
                              ele.row === 1 && (
                                <div className="th" key={ele.id}>
                                  <CustomSelectBox
                                    className="info"
                                    selected={ele}
                                    items={tableHeaders}
                                    handler={changeHeaderHandler}
                                  />
                                </div>
                              )
                          )}
                        {tableData.cells &&
                          tableData.cells.map((td) => (
                            <div className="td" key={td.id}>
                              <EditableMultiline
                                className="info"
                                text={td.text}
                                name={td.label}
                                handler={(e) => updateField(e, td.id, true)}
                                editable={auth.isAdmin()}
                              />
                            </div>
                          ))} */}
                      {/* </div> */}
                    </TableContainer>
                  )}
                </form>
              </InvoiceSection>

              <InvoiceSection
                style={{
                  display: "flex",
                  // justifyContent: "end",
                  justifyContent: "space-between",
                  margin: 0,
                }}
              >
                <CustomSearchDropdown
                  showQB={isShowQB}
                  buyerName={buyerName}
                  handler={getQuickBooksData}
                />

                {auth.isAdmin() && (
                  <DoxBtn
                    text="submit"
                    icon="check"
                    handler={submitHandler}
                    style={{
                      // margin: "18px 5px",
                      margin: "32px 5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                )}
              </InvoiceSection>
            </Sidebar>
          </SidebarWrapper>
        </>
      ) : (
        <WidgetsLoader />
      )}
    </DetailsWrapper>
  );
};

export default InvoiceDetails;
