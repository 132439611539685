import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";

import { PopUpWrapper } from "./style.js";
import DoxBtn from "@ui/Dox/DoxBtn/index.jsx";

const BoxPopUp = ({
  selected,
  // labels,
  fieldlabels,
  tableHeaders,
  saveHandler,
  deleteHandler,
  style,
  ocrText,
}) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [previousSelectedBox, setPreviousSelectedBox] = useState({});
  const [selectedOption, setSelectedOption] = useState(
    selected.type ? "label" : "table"
  );
  let labels = selectedOption === "table" ? tableHeaders : fieldlabels;

  const handleFieldTypeChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "table") {
      if (selectedBox.hasOwnProperty("ocr_text")) {
        selectedBox.text = selectedBox.ocr_text;
        delete selectedBox.ocr_text;
      }
      if (selectedBox.hasOwnProperty("type")) {
        delete selectedBox.type;
      }
    } else {
      if (selectedBox.hasOwnProperty("text")) {
        selectedBox.ocr_text = selectedBox.text;
        delete selectedBox.text;
      }
      if (!selectedBox.hasOwnProperty("type")) {
        selectedBox.type = "field";
      }
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.name === "label") {
      // find label object by selected value for aliasLabel
      const aliasText = labels.filter(
        (label) => label.value === e.target.value
      )[0];

      if (ocrText) {
        setSelectedBox({
          ...selectedBox,
          [e.target.name]: aliasText.key,
          ocr_text: ocrText,
          aliasLabel: aliasText.value,
        });
      } else {
        setSelectedBox({
          ...selectedBox,
          [e.target.name]: aliasText.key,
          aliasLabel: aliasText.value,
        });
      }
    } else if (e.target.name === "table") {
      // find table header object by selected value for aliasLabel
      const aliasText = labels.filter(
        (label) => label.value === e.target.value
      )[0];

      if (ocrText) {
        setSelectedBox({
          ...selectedBox,
          tableLabel: aliasText.key,
          text: ocrText,
          aliasLabel: aliasText.value,
        });
      }else{
        setSelectedBox({
          ...selectedBox,
          tableLabel: aliasText.key,
          aliasLabel: aliasText.value,
        });
      }
    } else {
      setSelectedBox({ ...selectedBox, [e.target.name]: e.target.value });
    }
  };

  const handleRowChange = (e) => {
    if (selectedBox?.row) {
      setSelectedBox({
        ...selectedBox,
        row: Number(e.target.value),
      });
    } else {
      setSelectedBox({
        ...selectedBox,
        row: Number(e.target.value),
        row_span: 1,
        col_span: 1,
      });
    }
  };

  useEffect(() => {
    setSelectedBox(selected);
    setPreviousSelectedBox(selected);
  }, [selected]);
  // console.log(selectedBox);
  return (
    <PopUpWrapper style={style}>
      {/* <div className="close">
        <CloseIcon />
      </div> */}
      <div className="body">
        <TextField
          id="outlined-multiline-static"
          label="Text"
          name={selectedBox?.row ? "text" : "ocr_text"}
          multiline
          rows={2}
          value={
            selectedBox?.ocr_text
              ? selectedBox.ocr_text
              : selectedBox?.text
              ? selectedBox.text
              : ocrText
          }
          onChange={onChangeHandler}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          select
          className="select"
          labelId="selectOption-label"
          label="Field"
          id="selectOption"
          value={selectedOption}
          onChange={handleFieldTypeChange}
        >
          <MenuItem value="label">Label</MenuItem>
          <MenuItem value="table">Table</MenuItem>
        </TextField>

        {selectedOption === "label" && labels.length > 0 && (
          <TextField
            select
            label="Label"
            name="label"
            value={(selectedBox?.aliasLabel && selectedBox.aliasLabel) || ""}
            onChange={onChangeHandler}
          >
            {labels.map((label) =>
              label.value !== "table" ? (
                <MenuItem key={label.key} value={label.value}>
                  {label.value}
                </MenuItem>
              ) : null
            )}
          </TextField>
        )}
        {selectedOption === "table" && (
          <div className="rowColContainer">
            {labels.length > 0 && (
              <TextField
                select
                label="Table Label"
                className="select"
                name="table"
                value={
                  (selectedBox?.aliasLabel && selectedBox.aliasLabel) || ""
                }
                onChange={onChangeHandler}
              >
                {labels.map((label) =>
                  label.value !== "table" ? (
                    <MenuItem key={label.key} value={label.value}>
                      {label.value}
                    </MenuItem>
                  ) : null
                )}
              </TextField>
            )}

            <TextField
              id="outlined-basic"
              className="rowInput"
              label="Row"
              name="row"
              variant="outlined"
              value={selectedBox?.row || ""}
              onChange={handleRowChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
      </div>
      <div className="footer">
        <DeleteForeverIcon
          className="icon delete"
          onClick={(e) => deleteHandler(e, selectedBox)}
        />
        <DoxBtn
          text="Save"
          handler={(e) => saveHandler(e, selectedBox, previousSelectedBox)}
          style={{
            height: "30px",
            margin: "18px 5px",
            display: "flex",
            alignItems: "center",
          }}
        />
      </div>
    </PopUpWrapper>
  );
};

export default BoxPopUp;
