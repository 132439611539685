import axios from "axios";
import api from "api";

// const instance = axios.create({});
const instance = api;

instance.interceptors.request.use((config) => {
  // const auth = useAuth();
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  // config.headers.common["Authorization"] = `bearer ${tokensData.access_token}`;
  config.headers["x-access-token"] = tokensData.accessToken;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("e", error);
    if (error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        // access_token: authData.access_token,
        refreshToken: authData.refreshToken,
      };

      let apiResponse = await api.post(
        `${process.env.REACT_APP_BASE_URL}/auth/refreshtoken`,
        payload
      );
      localStorage.setItem(
        "tokens",
        JSON.stringify({
          ...apiResponse.data,
          id: authData.id,
          email: authData.email,
          role:authData.role
        })
      );
      // auth.setUser(apiResponse.data);
      // error.config.headers["Authorization"] = `bearer ${apiResponse.data.access_token}`;
      error.config.headers["x-access-token"] = authData.accessToken;
      return api(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);
export default instance;
