import { nanoid } from "nanoid";
import React, { useEffect, useState, useRef } from "react";
import BoxPopUp from "../BoxPopUp";
import { CanvasWrapper } from "./style";
import { useAuth } from "@contexts/AuthProvider";

const CanvasImage = ({
  image,
  info,
  rawOcr,
  tableInfo,
  fieldlabels,
  tableHeaders,
  resizeHandler,
  updateHandler,
  deleteHandler,
}) => {
  const auth = useAuth();
  const canRef = useRef(null);
  const canDrwaRef = useRef(null);
  const containerRef = useRef();
  const [selectedLabel, setSelectedLabel] = useState({});
  const [pageChanged, setPageChanged] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [liveBox, setLiveBox] = useState({});
  const [popUpPos, setPopUpPos] = useState({ top: "0", left: "0" });
  const [ocrText, setOcrText] = useState("");
  let lineOffset = 10;
  let anchrSize = 12;
  let mousedown = false;
  let mousemove = false;
  let clickedArea = { box: -1, pos: "o" };
  let xmin = -1;
  let ymin = -1;
  let xmax = -1;
  let ymax = -1;

  let lineWidth = 5;
  let strokeColor = "#9a4cda";
  let tmpBox = null;
  // canvas context
  let ctx;

  const table = info && info.filter((ele) => ele.label === "table")[0];
  // const table = tableInfo;
  const detailsArr =
    info &&
    info.filter((ele) => ele.label !== "currency" && ele.label !== "table");

  let coOrdinatesArr =
    detailsArr &&
    detailsArr.map(
      (ele) =>
        ele.label !== "table" && {
          id: ele.id,
          xmin: ele.xmin,
          ymin: ele.ymin,
          xmax: ele.xmax - ele.xmin,
          ymax: ele.ymax - ele.ymin,
        }
    );

  const tableCordinates =
    table &&
    // table?.map((ele) => ({
    table.cells?.map((ele) => ({
      id: ele.id,
      xmin: ele.xmin,
      ymin: ele.ymin,
      xmax: ele.xmax - ele.xmin,
      ymax: ele.ymax - ele.ymin,
    }));

  if (tableCordinates && tableCordinates) {
    coOrdinatesArr = tableCordinates && [...coOrdinatesArr, ...tableCordinates];
  } else {
    coOrdinatesArr = [...coOrdinatesArr];
  }

  // coOrdinatesArr = tableCordinates &&  [...coOrdinatesArr, ...tableCordinates];

  const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;
  const getTopLeft = () => {
    const rect = canDrwaRef.current.getBoundingClientRect();
    if (!isEmptyObject(selectedLabel)) {
      const left = Math.floor(
        selectedLabel.xmin /
          (canDrwaRef.current.width / canDrwaRef.current.clientWidth)
      );
      const top =
        Math.floor(
          selectedLabel.ymax /
            (canDrwaRef.current.height / canDrwaRef.current.clientHeight)
        ) + 10;
      setPopUpPos({ top: top, left: left });
    }
    // left=left;
  };
  //drawBox is used to draw red box and values coming from backend
  const drawBox = () => {
    // console.log("draw")
    const img = new Image();
    ctx = canRef.current.getContext("2d");
    // console.log(ctx)
    if (pageChanged) {
      ctx.clearRect(0, 0, canRef.current.width, canRef.current.height);
      setPageChanged(false);
    }
    img.src = image;
    img.onload = () => {
      canRef.current.width = img && img.width;
      canRef.current.height = img && img.height;
      canDrwaRef.current.width = canRef.current.width;
      canDrwaRef.current.height = canRef.current.height;
      // draw image on canvas
      ctx.drawImage(img, 0, 0, canRef.current.width, canRef.current.height);
      for (let ele of coOrdinatesArr) {
        if (ele.id !== selectedLabel.id) {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.strokeRect(ele.xmin, ele.ymin, ele.xmax, ele.ymax);
        }
      }
      redraw();
    };
  };

  const deleteBoxHandler = (e, obj) => {
    setSelectedLabel({});
    setBoxes([]);
    deleteHandler(e, obj);
  };

  const updateBoxHandler = (e, obj, previousObj) => {
    if (Object.keys(liveBox).length > 0) {
      obj.xmin = liveBox.xmin;
      obj.ymin = liveBox.ymin;
      obj.xmax = liveBox.xmax;
      obj.ymax = liveBox.ymax;
    }

    setSelectedLabel({});
    setBoxes([]);
    updateHandler(e, obj, previousObj);
  };

  const mouseMoveHandler = (e) => {
    // mousemove = true;
    // reset cursor

    const rect = canDrwaRef.current.getBoundingClientRect();
    const xPt = Math.floor(
      (e.clientX - rect.left) *
        (canDrwaRef.current.width / canDrwaRef.current.clientWidth)
    );
    const yPt = Math.floor(
      (e.clientY - rect.top) *
        (canDrwaRef.current.height / canDrwaRef.current.clientHeight)
    );
    let isResize = findCurrentArea(xPt, yPt);

    canDrwaRef.current.style.cursor = "default";
    if (
      (isResize.pos === "br" || isResize.pos === "tl") &&
      !isEmptyObject(selectedLabel)
    ) {
      canDrwaRef.current.style.cursor = "nw-resize";
    } else if (
      (isResize.pos === "bl" || isResize.pos === "tr") &&
      !isEmptyObject(selectedLabel)
    ) {
      canDrwaRef.current.style.cursor = "ne-resize";
    } else if (
      isLabelBox(e, info) ||
      isTableBox(e, info) ||
      isResize.box >= "0"
    ) {
      canDrwaRef.current.style.cursor = "pointer";
    }
  };

  const isLabelBox = (e, labels) => {
    // e.preventDefault();
    // e.stopPropagation();
    const rect = canRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPoint = Math.floor(
      x * (canRef.current.width / canRef.current.clientWidth)
    );
    const yPoint = Math.floor(
      y * (canRef.current.height / canRef.current.clientHeight)
    );

    return labels.find(function (element) {
      return (
        yPoint > element.ymin &&
        yPoint < element.ymax &&
        xPoint > element.xmin &&
        xPoint < element.xmax
      );
    });
  };

  const isTableBox = (e, labels) => {
    // e.preventDefault();
    // e.stopPropagation();
    const rect = canRef.current.getBoundingClientRect();

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPoint = Math.floor(
      x * (canRef.current.width / canRef.current.clientWidth)
    );
    const yPoint = Math.floor(
      y * (canRef.current.height / canRef.current.clientHeight)
    );

    const tables = labels.filter(function (element) {
      return element.label === "table";
    });

    for (const table of tables) {
      const cell = table.cells.find(function (element) {
        return (
          yPoint > element.ymin &&
          yPoint < element.ymax &&
          xPoint > element.xmin &&
          xPoint < element.xmax
        );
      });
      if (cell) {
        return cell;
      }
    }
  };

  const onClickHandler = (e) => {
    e.stopPropagation();

    if (!mousemove) {
      let clickedObj = isLabelBox(e, info) || isTableBox(e, info);
      if (!clickedObj) {
        clickedObj = isLabelBox(e, boxes);
      }
      if (clickedObj?.label === "table") {
        clickedObj = isLabelBox(e, clickedObj.cells);
      }
      if (clickedObj) {
        if (clickedObj?.id !== selectedLabel.id) {
          setSelectedLabel(clickedObj);
        }

        if (clickedObj.xmin) {
          setBoxes([
            newBox(
              clickedObj.xmin,
              clickedObj.ymin,
              clickedObj.xmax,
              clickedObj.ymax,
              clickedObj.id
            ),
          ]);
        }
      }
    }
  };

  const mouseDownHandler = (e) => {
    e.stopPropagation();
    const rect = canDrwaRef.current.getBoundingClientRect();
    mousedown = true;
    const xPt = Math.floor(
      (e.clientX - rect.left) *
        (canDrwaRef.current.width / canDrwaRef.current.clientWidth)
    );
    const yPt = Math.floor(
      (e.clientY - rect.top) *
        (canDrwaRef.current.height / canDrwaRef.current.clientHeight)
    );

    clickedArea = findCurrentArea(xPt, yPt);

    xmin = xPt;
    ymin = yPt;
    xmax = xPt;
    ymax = yPt;
  };
  const mouseUpHandler = (e) => {
    if (clickedArea.box === -1 && tmpBox !== null) {
      // setBoxes([...boxes, tmpBox]);
      setBoxes([tmpBox]);
      setSelectedLabel(tmpBox);
    } else if (clickedArea.box !== -1) {
      var selectedBox = boxes[clickedArea.box];
      if (selectedBox.xmin > selectedBox.xmax) {
        var previousX1 = selectedBox.xmin;
        selectedBox.xmin = selectedBox.xmax;
        selectedBox.xmax = previousX1;
      }
      if (selectedBox.ymin > selectedBox.ymax) {
        var previousY1 = selectedBox.ymin;
        selectedBox.ymin = selectedBox.ymax;
        selectedBox.ymax = previousY1;
      }
      // update state with new resized values
      const resizedBox = boxes[clickedArea.box];
      const newObj = {
        ...selectedLabel,
        xmin: resizedBox.xmin,
        ymin: resizedBox.ymin,
        xmax: resizedBox.xmax,
        ymax: resizedBox.ymax,
      };
      resizeHandler(e, newObj, newObj, true);
      setSelectedLabel({ ...newObj });
    } else {
      // reset boxes if selectedLabel is a field
      if (selectedLabel.score) {
        setBoxes([]);
      }
      setSelectedLabel({});
    }

    clickedArea = { box: -1, pos: "o" };
    tmpBox = null;
    mousedown = false;

    redraw();
    onClickHandler(e);
  };

  const mouseOutHandler = (e) => {
    if (clickedArea.box !== -1) {
      var selectedBox = boxes[clickedArea.box];
      if (selectedBox.xmin > selectedBox.xmax) {
        var previousX1 = selectedBox.xmin;
        selectedBox.xmin = selectedBox.xmax;
        selectedBox.xmax = previousX1;
      }
      if (selectedBox.ymin > selectedBox.ymax) {
        var previousY1 = selectedBox.ymin;
        selectedBox.ymin = selectedBox.ymax;
        selectedBox.ymax = previousY1;
      }
    }
    mousedown = false;
    clickedArea = { box: -1, pos: "o" };
    tmpBox = null;
  };
  const mouseMove = (e) => {
    // e.stopPropagation();
    const rect = canDrwaRef.current.getBoundingClientRect();

    if (mousedown && clickedArea.box === -1) {
      xmax = Math.floor(
        (e.clientX - rect.left) *
          (canDrwaRef.current.width / canDrwaRef.current.clientWidth)
      );
      ymax = Math.floor(
        (e.clientY - rect.top) *
          (canDrwaRef.current.height / canDrwaRef.current.clientHeight)
      );
      //set mousemove true for new box
      mousemove = true;
      redraw();
    } else if (
      mousedown &&
      clickedArea.box !== -1 &&
      !isEmptyObject(selectedLabel)
    ) {
      // box resizing events
      xmax = Math.floor(
        (e.clientX - rect.left) *
          (canDrwaRef.current.width / canDrwaRef.current.clientWidth)
      );
      ymax = Math.floor(
        (e.clientY - rect.top) *
          (canDrwaRef.current.height / canDrwaRef.current.clientHeight)
      );

      const xOffset = xmax - xmin;
      const yOffset = ymax - ymin;
      xmin = xmax;
      ymin = ymax;

      //set mousemove true for resize
      mousemove = true;

      if (
        clickedArea.pos === "i" ||
        clickedArea.pos === "tl" ||
        clickedArea.pos === "l" ||
        clickedArea.pos === "bl"
      ) {
        boxes[clickedArea.box].xmin += xOffset;
      }
      if (
        clickedArea.pos === "i" ||
        clickedArea.pos === "tl" ||
        clickedArea.pos === "t" ||
        clickedArea.pos === "tr"
      ) {
        boxes[clickedArea.box].ymin += yOffset;
      }
      if (
        clickedArea.pos === "i" ||
        clickedArea.pos === "tr" ||
        clickedArea.pos === "r" ||
        clickedArea.pos === "br"
      ) {
        boxes[clickedArea.box].xmax += xOffset;
      }
      if (
        clickedArea.pos === "i" ||
        clickedArea.pos === "bl" ||
        clickedArea.pos === "b" ||
        clickedArea.pos === "br"
      ) {
        boxes[clickedArea.box].ymax += yOffset;
      }

      redraw();
    } else {
      mouseMoveHandler(e);
    }
  };

  const redraw = () => {
    // canvas.width = canvas.width;
    // canDrwaRef.current.width = canRef.current.width;
    // canDrwaRef.current.height = canRef.current.height;
    var context = canDrwaRef.current.getContext("2d");

    context.clearRect(
      0,
      0,
      canDrwaRef.current.width,
      canDrwaRef.current.height
    );
    context.beginPath();
    for (var i = 0; i < boxes.length; i++) {
      if (boxes[i].id === selectedLabel.id) {
        drawResizeBoxOn(boxes[i], context);
      }
      drawRectBox(boxes[i], context);
    }

    if (clickedArea.box === -1) {
      tmpBox = newBox(xmin, ymin, xmax, ymax);
      if (tmpBox !== null) {
        drawResizeBoxOn(tmpBox, context);
      }
    }
  };

  const findCurrentArea = (x, y) => {
    for (var i = 0; i < boxes.length; i++) {
      var box = boxes[i];
      const xCenter = box.xmin + (box.xmax - box.xmin) / 2;
      const yCenter = box.ymin + (box.ymax - box.ymin) / 2;
      if (box.xmin - lineOffset < x && x < box.xmin + lineOffset) {
        if (box.ymin - lineOffset < y && y < box.ymin + lineOffset) {
          return { box: i, pos: "tl" };
        } else if (box.ymax - lineOffset < y && y < box.ymax + lineOffset) {
          return { box: i, pos: "bl" };
        } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
          return { box: i, pos: "l" };
        }
      } else if (box.xmax - lineOffset < x && x < box.xmax + lineOffset) {
        if (box.ymin - lineOffset < y && y < box.ymin + lineOffset) {
          return { box: i, pos: "tr" };
        } else if (box.ymax - lineOffset < y && y < box.ymax + lineOffset) {
          return { box: i, pos: "br" };
        } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
          return { box: i, pos: "r" };
        }
      } else if (xCenter - lineOffset < x && x < xCenter + lineOffset) {
        if (box.ymin - lineOffset < y && y < box.ymin + lineOffset) {
          return { box: i, pos: "t" };
        } else if (box.ymax - lineOffset < y && y < box.ymax + lineOffset) {
          return { box: i, pos: "b" };
        } else if (box.ymin - lineOffset < y && y < box.ymax + lineOffset) {
          return { box: i, pos: "i" };
        }
      } else if (box.xmin - lineOffset < x && x < box.xmax + lineOffset) {
        if (box.ymin - lineOffset < y && y < box.ymax + lineOffset) {
          return { box: i, pos: "i" };
        }
      }
    }
    return { box: -1, pos: "o" };
  };

  const newBox = (xmin, ymin, xmax, ymax, id = nanoid()) => {
    const boxX1 = xmin < xmax ? xmin : xmax;
    const boxY1 = ymin < ymax ? ymin : ymax;
    const boxX2 = xmin > xmax ? xmin : xmax;
    const boxY2 = ymin > ymax ? ymin : ymax;
    if (boxX2 - boxX1 > lineOffset * 2 && boxY2 - boxY1 > lineOffset * 2) {
      return {
        id: id,
        xmin: boxX1,
        ymin: boxY1,
        xmax: boxX2,
        ymax: boxY2,
        isNew: true,
        type: "field",
      };
    } else {
      return null;
    }
  };

  const drawRectBox = (box, context) => {
    context.strokeStyle = strokeColor;
    context.fillStyle = strokeColor;

    context.rect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin);
    context.lineWidth = lineWidth;
    context.stroke();
    if (!mousemove && rawOcr) {
      setLiveBox(box);
      const imageWidth = canDrwaRef.current.width;
      const imageHeight = canDrwaRef.current.height;

      // Scale and get ocr_text inside the box
      const ocrTextsInsideBox = rawOcr
        .filter((ocrItem) => {
          // Scale the relative coordinates to absolute coordinates based on image dimensions
          const absoluteXmin = (ocrItem.xmin * imageWidth) / 100;
          const absoluteXmax = absoluteXmin + (ocrItem.xmax * imageWidth) / 100;
          const absoluteYmin = (ocrItem.ymin * imageHeight) / 100;
          const absoluteYmax =
            absoluteYmin + (ocrItem.ymax * imageHeight) / 100;
          return (
            absoluteXmin >= box.xmin &&
            absoluteXmax <= box.xmax &&
            absoluteYmin >= box.ymin &&
            absoluteYmax <= box.ymax
          );
        })
        .map((ocrItem) => ocrItem.ocr_text)
        .join(" ");
      setOcrText(ocrTextsInsideBox);
    }
  };
  const drawResizeBoxOn = (box, context) => {
    // const xCenter = box.xmin + (box.xmax - box.xmin) / 2;
    // const yCenter = box.ymin + (box.ymax - box.ymin) / 2;
    context.strokeStyle = strokeColor;
    context.fillStyle = strokeColor;

    context.rect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin);
    context.lineWidth = lineWidth;
    context.stroke();

    context.fillRect(
      box.xmin - anchrSize,
      box.ymin - anchrSize,
      2 * anchrSize,
      2 * anchrSize
    );
    // context.fillRect(
    //   box.xmin - anchrSize,
    //   yCenter - anchrSize,
    //   2 * anchrSize,
    //   2 * anchrSize
    // );
    context.fillRect(
      box.xmin - anchrSize,
      box.ymax - anchrSize,
      2 * anchrSize,
      2 * anchrSize
    );
    // context.fillRect(
    //   xCenter - anchrSize,
    //   box.ymin - anchrSize,
    //   2 * anchrSize,
    //   2 * anchrSize
    // );
    // context.fillRect(
    //   xCenter - anchrSize,
    //   yCenter - anchrSize,
    //   2 * anchrSize,
    //   2 * anchrSize
    // );
    // context.fillRect(
    //   xCenter - anchrSize,
    //   box.ymax - anchrSize,
    //   2 * anchrSize,
    //   2 * anchrSize
    // );
    context.fillRect(
      box.xmax - anchrSize,
      box.ymin - anchrSize,
      2 * anchrSize,
      2 * anchrSize
    );
    // context.fillRect(
    //   box.xmax - anchrSize,
    //   yCenter - anchrSize,
    //   2 * anchrSize,
    //   2 * anchrSize
    // );
    context.fillRect(
      box.xmax - anchrSize,
      box.ymax - anchrSize,
      2 * anchrSize,
      2 * anchrSize
    );
    if (!mousemove && rawOcr) {
      setLiveBox(box);
      const imageWidth = canDrwaRef.current.width;
      const imageHeight = canDrwaRef.current.height;

      // Scale and get ocr_text inside the box
      const ocrTextsInsideBox = rawOcr
        .filter((ocrItem) => {
          // Scale the relative coordinates to absolute coordinates based on image dimensions
          const absoluteXmin = (ocrItem.xmin * imageWidth) / 100;
          const absoluteXmax = absoluteXmin + (ocrItem.xmax * imageWidth) / 100;
          const absoluteYmin = (ocrItem.ymin * imageHeight) / 100;
          const absoluteYmax =
            absoluteYmin + (ocrItem.ymax * imageHeight) / 100;
          return (
            absoluteXmin >= box.xmin &&
            absoluteXmax <= box.xmax &&
            absoluteYmin >= box.ymin &&
            absoluteYmax <= box.ymax
          );
        })
        .map((ocrItem) => ocrItem.ocr_text)
        .join(" ");
      setOcrText(ocrTextsInsideBox);
    }
  };

  useEffect(() => {
    setPageChanged(true);
  }, [image]);
  useEffect(() => {
    drawBox();
    getTopLeft();
    // changeSelectedBox();
  }, [image, selectedLabel]);
  const boxLabels = selectedLabel.row ? tableHeaders : fieldlabels;

  useEffect(() => {
    drawBox();
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        drawBox();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <CanvasWrapper ref={containerRef}>
      <canvas
        ref={canRef}
        style={{
          width: "100%",
          // height: "100%",
          objectFit: "cover",
          border: "1px solid",
          zIndex: "1",
        }}
      />

      {auth.isAdmin() || auth.isSuperAdmin() ? (
        //Show edit labels canvas only to admin user
        <canvas
          ref={canDrwaRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            // height: "100%",
            objectFit: "cover",
            border: "1px solid",
            zIndex: "1",
          }}
          // onClick={onClickHandler}
          onPointerMove={mouseMove}
          // onMouseMove={mouseMoveHandler}
          onPointerDown={mouseDownHandler}
          onPointerUp={mouseUpHandler}
          onPointerOut={mouseOutHandler}
        />
      ) : (
        <canvas
          ref={canDrwaRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            // height: "100%",
            objectFit: "cover",
            border: "1px solid",
            zIndex: "1",
          }}
        />
      )}

      {!isEmptyObject(selectedLabel) && (
        <BoxPopUp
          labels={boxLabels.length > 0 && boxLabels}
          selected={selectedLabel}
          fieldlabels={fieldlabels}
          tableHeaders={tableHeaders}
          saveHandler={updateBoxHandler}
          deleteHandler={deleteBoxHandler}
          style={{
            top: popUpPos?.top,
            left: popUpPos?.left,
            display: !isEmptyObject(selectedLabel) && "flex",
          }}
          ocrText={ocrText}
        />
      )}
    </CanvasWrapper>
  );
};

export default CanvasImage;
