import { useLocalStorage } from "@hooks/dox/useLocalStorage";
import { createContext, useContext, useEffect, useState } from "react";

export const InterfaceContext = createContext(undefined);

export const InterfaceContextAPI = ({ children }) => {
  const page = document.documentElement;
  const browserTheme = window.matchMedia("(prefers-color-scheme: dark)");
  const persisted = JSON.parse(localStorage.getItem("preferences") || "{}");
  // const [isDarkMode, setIsDarkMode] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    persisted ? persisted.isDarkMode : browserTheme.matches
  );
  const [isContrastMode, setIsContrastMode] = useState(
    persisted.isContrastMode || false
  );

  const [fontScale, setFontScale] = useState(persisted.fontScale || 1);
  const [direction, setDirection] = useState(persisted.direction || "ltr");
  const [isLayoutEditable, setIsLayoutEditable] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [uploaded, setUploaded] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadCount, setUploadCount] = useState({});
  const [totalUploadCount, setTotalUploadCount] = useState({});
  const [showDrivePopup, setShowDrivePopup] = useState(false);
  const [extracted, setExtracted] = useState({});
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isShowMenu1, setIsShowMenu1] = useState(true);
  const [isShowBot, setIsShowBot] = useState(true);
  const [sliderVal, setSliderVal] = useState(1000);
  const [basicPrice, setBasicPrice] = useState(500);
  const [proPrice, setProPrice] = useState(750);
  const [yearlyBasicPrice, setYearlyBasicPrice] = useState(0);
  const [yearlyProPrice, setYearlyProPrice] = useState(0);
  const [actualYearlyBasicPrice, setActualYearlyBasicPrice] = useState(0);
  const [actualYearlyProPrice, setActualYearlyProPrice] = useState(0);
  const [planDuration, setPlanDuration] = useState("Monthly");
  const [notification, setNotification] = useLocalStorage("notifications", {
    notifications: [],
    isMarkAsRead: true,
  });
  const [isUploadPopUpShow, setIsUploadPopUpShow] = useState(false);
  const [isShowContactDetailsPopUp, setIsShowContactDetailsPopUp] =
    useState(false);
  const [showIntegratedApps, setShowIntegratedApps] = useState({
    quickbooksOnline: false,
  });
  const [qbItems, setQBItems] = useState([]);
  const [qbCustomer, setQBCustomer] = useState({});
  const [intStatus, setIntStatus] = useState({
    linkedIntegrations: [],
    unlinkedIntegrations: [],
  });
  const [workflowImportPopup, setWorkflowImportPopup] = useState(false);
  const [workflowEnhancePopup, setWorkflowEnhancePopup] = useState(false);
  const [workflowExportPopup, setWorkflowExportPopup] = useState(false);
  const [conditionOption, setConditionOption] = useState(false);
  const [selectedDataAction, setSelectedDataAction] = useState("");
  const [enhanceThenAction, setEnhanceThenAction] = useState("");
  const [enhanceAddActionPopup, setEnhanceAddActionPopup] = useState(false);

  const stopTransition = () => {
    page.classList.add("no-transition");
    setTimeout(() => page.classList.remove("no-transition"), 100);
  };

  const savePreferences = () => {
    localStorage.setItem(
      "preferences",
      JSON.stringify({
        isDarkMode,
        isContrastMode,
        fontScale,
        direction,
      })
    );
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    stopTransition();
  };

  //recent code for new dashboard
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const theme = {
    isDarkMode,
    toggleTheme,
  };

  const toggleContrastMode = () => {
    setIsContrastMode(!isContrastMode);
    page.classList.toggle("contrast");
  };

  const changeFontScale = (scale) => {
    setFontScale(scale);
    stopTransition();
  };

  const toggleDirection = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    page.setAttribute("dir", direction);
  };

  const toggleLayoutEditable = () => {
    setIsLayoutEditable(!isLayoutEditable);
  };

  useEffect(() => {
    page.style.setProperty("--font-scale", fontScale);
    page.dataset.ratio = `${window.devicePixelRatio}`;
    savePreferences();

    window.addEventListener("resize", () => setIsLayoutEditable(false));
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        if (event.matches) {
          setIsDarkMode(true);
        } else {
          setIsDarkMode(false);
        }
        stopTransition();
        savePreferences();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDarkMode,
    isContrastMode,
    fontScale,
    direction,
    window.devicePixelRatio,
  ]);

  useEffect(() => {
    const isUploaded = Object.keys(totalUploadCount).every((key) => {
      return uploadCount[key] >= totalUploadCount[key];
    });

    if (isUploaded) {
      setIsUploading(false);
      if (
        Object.keys(uploadCount).length > 0 &&
        Object.keys(totalUploadCount).length > 0
      ) {
        setUploadCount({});
        setTotalUploadCount({});
      }
    }
  }, [totalUploadCount, uploadCount]);

  return (
    <InterfaceContext.Provider
      value={{
        isDarkMode,
        isContrastMode,
        fontScale,
        direction,
        toggleDarkMode,
        toggleContrastMode,
        changeFontScale,
        toggleDirection,
        isLayoutEditable,
        toggleLayoutEditable,
        setIsLayoutEditable,
        pageTitle,
        setPageTitle,
        isUploadPopUpShow,
        setIsUploadPopUpShow,
        uploaded,
        setUploaded,
        extracted,
        setExtracted,
        notification,
        setNotification,
        documentType,
        setDocumentType,
        companyId,
        setCompanyId,
        companyName,
        setCompanyName,
        isShowMenu1,
        setIsShowMenu1,
        isShowBot,
        setIsShowBot,
        sliderVal,
        setSliderVal,
        basicPrice,
        setBasicPrice,
        proPrice,
        setProPrice,
        planDuration,
        setPlanDuration,
        yearlyBasicPrice,
        setYearlyBasicPrice,
        yearlyProPrice,
        setYearlyProPrice,
        actualYearlyBasicPrice,
        setActualYearlyBasicPrice,
        actualYearlyProPrice,
        setActualYearlyProPrice,
        isShowContactDetailsPopUp,
        setIsShowContactDetailsPopUp,
        showDrivePopup,
        setShowDrivePopup,
        isUploading,
        setIsUploading,
        uploadCount,
        setUploadCount,
        totalUploadCount,
        setTotalUploadCount,
        showIntegratedApps,
        setShowIntegratedApps,
        intStatus,
        setIntStatus,
        qbItems,
        setQBItems,
        qbCustomer,
        setQBCustomer,
        theme,
        workflowImportPopup,
        setWorkflowImportPopup,
        workflowEnhancePopup,
        setWorkflowEnhancePopup,
        workflowExportPopup,
        setWorkflowExportPopup,
        conditionOption,
        setConditionOption,
        selectedDataAction,
        setSelectedDataAction,
        enhanceAddActionPopup,
        setEnhanceAddActionPopup,
        enhanceThenAction,
        setEnhanceThenAction,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};

export const useInterfaceContext = () => useContext(InterfaceContext);
