import React from "react";
import styled from "styled-components";

const StyledSVG = styled.svg`
  &:hover .gradient,
  &:focus .gradient {
    fill: url(#linearGradient);
  }
  &:hover .fillWhite,
  &:focus .fillWhite {
    fill: #fff;
  }
`;

const SubscriptionIcon = ({active}) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 224 202"
      fill="none"
      className="icon"
    >
      <path
        className="gradient"
        d="M215.292 117.486C206.124 117.486 198.665 110.095 198.665 101.011C198.665 91.916 206.124 84.5248 215.292 84.5248C217.514 84.5248 219.647 83.6507 221.211 82.1016C222.785 80.5415 223.667 78.4282 223.667 76.2263L223.656 46.3297C223.656 21.0581 202.897 0.5 177.393 0.5H46.6087C21.104 0.5 0.345151 21.0581 0.345151 46.3297L0.333984 77.1889C0.333984 79.3908 1.21615 81.5041 2.79065 83.0642C4.35398 84.6133 6.48682 85.4874 8.70898 85.4874C18.1895 85.4874 25.3362 92.1594 25.3362 101.011C25.3362 110.095 17.8768 117.486 8.70898 117.486C4.08598 117.486 0.333984 121.204 0.333984 125.785V155.659C0.333984 180.931 21.0816 201.5 46.5975 201.5H177.404C202.92 201.5 223.667 180.931 223.667 155.659V125.785C223.667 121.204 219.915 117.486 215.292 117.486Z"
        // fill="white"
        fill={active ? "url(#linearGradient)" : "white"}
      />
      <path
        className="fillWhite"
        d="M150.308 96.406L137.142 109.225L140.258 127.349C140.794 130.487 139.532 133.58 136.952 135.434C134.395 137.31 131.056 137.544 128.242 136.048L111.995 127.505L95.714 136.059C94.508 136.695 93.1903 137.03 91.8838 137.03C90.1753 137.03 88.4891 136.494 87.0375 135.445C84.4691 133.58 83.2073 130.487 83.7433 127.349L86.8476 109.225L73.6821 96.406C71.4041 94.195 70.6113 90.9455 71.594 87.9194C72.5878 84.9044 75.1561 82.7604 78.294 82.3137L96.451 79.6672L104.591 63.174C106.01 60.3377 108.846 58.5734 111.995 58.5734H112.017C115.177 58.5845 118.014 60.3489 119.41 63.1852L127.55 79.6672L145.741 82.3249C148.845 82.7604 151.413 84.9044 152.396 87.9194C153.39 90.9455 152.597 94.195 150.308 96.406Z"
        // fill="#9A9A9A"
        fill={active ? "white" :"#9A9A9A"}
      />
    </StyledSVG>
  );
};

export default SubscriptionIcon;
