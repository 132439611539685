import React from "react";

const ReviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 240 240"
      fill="none"
      className="icon"
    >
      <defs>
        <linearGradient id="linearGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#ed6804" />
          <stop offset="50%" stopColor="#e0192a" />
          <stop offset="100%" stopColor="#a508a5" />
        </linearGradient>
      </defs>
      <path
        className="gradient"
        d="M50 10.0002C29.2893 10.0002 12.5 26.7895 12.5 47.5002V187.5C12.5 208.211 29.2893 225 50 225H150C170.711 225 187.5 208.211 187.5 187.5V77.5002C187.5 75.5111 186.71 73.6034 185.303 72.1969L125.303 12.1969C123.897 10.7904 121.989 10.0002 120 10.0002H50Z"
        fill="white"
      />
      <path
        className="gradient"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.531 9.84375V225H58.4598C33.1632 225 12.6562 208.199 12.6562 187.473V47.3711C12.6562 26.6453 33.1632 9.84375 58.4598 9.84375H119.531Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155 127.5C139.812 127.5 127.5 139.812 127.5 155C127.5 170.188 139.812 182.5 155 182.5C170.188 182.5 182.5 170.188 182.5 155C182.5 139.812 170.188 127.5 155 127.5ZM112.5 155C112.5 131.528 131.528 112.5 155 112.5C178.472 112.5 197.5 131.528 197.5 155C197.5 178.472 178.472 197.5 155 197.5C131.528 197.5 112.5 178.472 112.5 155Z"
        fill="#9A9A9A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.697 174.697C177.626 171.768 182.374 171.768 185.303 174.697L225.303 214.697C228.232 217.626 228.232 222.374 225.303 225.303C222.374 228.232 217.626 228.232 214.697 225.303L174.697 185.303C171.768 182.374 171.768 177.626 174.697 174.697Z"
        fill="#9A9A9A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.555 135.555C130.578 140.531 127.5 147.406 127.5 155C127.5 170.188 139.812 182.5 155 182.5C162.594 182.5 169.469 179.422 174.445 174.446L225.303 225.303C222.374 228.232 217.626 228.232 214.697 225.303L179.28 189.887C172.397 194.686 164.027 197.5 155 197.5C131.528 197.5 112.5 178.472 112.5 155C112.5 143.264 117.257 132.639 124.948 124.948L135.555 135.555Z"
        fill="#9A9A9A"
      />
      <path
      className="fillWhite"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5 70C42.5 65.8579 45.8579 62.5 50 62.5H100C104.142 62.5 107.5 65.8579 107.5 70C107.5 74.1421 104.142 77.5 100 77.5H50C45.8579 77.5 42.5 74.1421 42.5 70ZM42.5 120C42.5 115.858 45.8579 112.5 50 112.5H100C104.142 112.5 107.5 115.858 107.5 120C107.5 124.142 104.142 127.5 100 127.5H50C45.8579 127.5 42.5 124.142 42.5 120ZM42.5 170C42.5 165.858 45.8579 162.5 50 162.5H100C104.142 162.5 107.5 165.858 107.5 170C107.5 174.142 104.142 177.5 100 177.5H50C45.8579 177.5 42.5 174.142 42.5 170Z"
        fill="#9A9A9A"
      />
      <path
      className="fillWhite"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 162.5V177.5H50C45.8579 177.5 42.5 174.142 42.5 170C42.5 165.858 45.8579 162.5 50 162.5H100ZM100 112.5V127.5H50C45.8579 127.5 42.5 124.142 42.5 120C42.5 115.858 45.8579 112.5 50 112.5H100ZM100 62.5V77.5H50C45.8579 77.5 42.5 74.1421 42.5 70C42.5 65.8579 45.8579 62.5 50 62.5H100Z"
        fill="#9A9A9A"
      />
    </svg>
  );
};

export default ReviewIcon;
