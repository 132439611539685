import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "api";
// styling
import {
  DetailsWrapper,
  BackBtn,
  Content,
  InvoiceSection,
  Sidebar,
} from "../style.js";

// material icons
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";

import Editable from "@ui/Dox/Editable/index.js";
import DoxBtn from "@ui/Dox/DoxBtn";
import WidgetsLoader from "@components/WidgetsLoader";
import { useAuth } from "@contexts/AuthProvider";
import { nanoid } from "nanoid";

const AdhaarDetails = ({ state }) => {
  const [data, setData] = useState({});
  const [details, setDetails] = useState({});
  const [newFields, setNewFields] = useState({});
  const [updated, setUpdated] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isShowField, setIsShowField] = useState(false);
  const fieldNameRef = useRef("");
  const fieldValueRef = useRef("");
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { payload, config, ext } = state;

  const body = {
    documentId: payload.documentId,
    documentType: payload.documentSubType,
  };

  const updateField = (e) => {
    if (e.key === "Enter") {
      const updateObj = {
        documentId: data._id,
        documentType: "KycAdhar",
        AdharNo: data.AdharNo,
        FullName: data.FullName,
        Gender: data.Gender,
        Address: data.Address,
        DateOfBirth: data.DateOfBirth,
      };
      setIsChanged(true);
      setUpdated({ ...updateObj, [e.target.name]: e.target.value });
      setData({ ...data, [e.target.name]: e.target.value });
      // setIsEditable(false);
    }
  };

  const updateCustomField = (e) => {
    console.log("custom", e.target.name, e.target.value);
    const name = e.target.name;
    const val = e.target.value;
    setData({ ...data, CustomFields: { ...data.CustomFields, [name]: val } });
    setIsChanged(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const closeFieldHandler = () => {
    fieldNameRef.current.value = "";
    fieldValueRef.current.value = "";
    setIsShowField(false);
  };

  const submitHandler = async () => {
    const obj = data;
    delete obj["fileData"];
    console.log({ ...obj });

    try {
      setIsLoading(true);
      const res = await api.post(
        "/document/updateDocumentData",
        { ...updated },
        {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        }
      );
      // console.log(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
    goBack();
  };

  const addFieldHandler = (e) => {
    if (
      fieldNameRef.current.value &&
      fieldValueRef.current.value &&
      e.key === "Enter"
    ) {
      let name = fieldNameRef.current.value;
      let val = fieldValueRef.current.value;
      setNewFields({ ...newFields, [name]: val });
      setData({ ...data, CustomFields: { ...data.CustomFields, [name]: val } });
      fieldNameRef.current.value = "";
      fieldValueRef.current.value = "";
      setIsShowField(false);
      setIsChanged(true);
    }
  };

  const getDetails = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/document/showDocumentDetails", body, config);

      setDetails(res.data);
      let fileInfo = res.data;
      setData(fileInfo);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // setData(Extracted);
    getDetails();
  }, []);

  console.log(data);
  return (
    <DetailsWrapper>
      <BackBtn
        text="Back"
        icon="chevron-left"
        handler={goBack}
        className="primary"
      />
      {!isLoading ? (
        <>
          <Content>
            {/* <img src={Invoice} alt="invoice" /> */}
            {ext === "pdf" ? (
              <embed
                src={`data:application/pdf;base64,${details.fileData}`}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={`data:image/png;base64,${details.fileData}`}
                alt="invoice"
              />
            )}
          </Content>
          <Sidebar style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <InvoiceSection>
                <div className="title">
                  <InfoIcon className="icon" /> Basic Information
                </div>

                <div className="details">
                  <div className="info-wrapper">
                    <span className="name">Adhaar Number</span>
                    <Editable
                      text={data["AdharNo"]}
                      name="AdharNo"
                      handler={updateField}
                      //   editable={false}
                    />
                  </div>
                  <div className="info-wrapper">
                    <span className="name">FullName</span>
                    <Editable
                      text={data["FullName"]}
                      name="FullName"
                      handler={updateField}
                    />
                  </div>
                  <div className="info-wrapper">
                    <span className="name">Gender</span>
                    <Editable
                      text={data["Gender"]}
                      name="Gender"
                      handler={updateField}
                    />
                  </div>
                  <div className="info-wrapper">
                    <span className="name">Address</span>
                    <Editable
                      text={data["Address"]}
                      name="Address"
                      handler={updateField}
                    />
                  </div>
                  <div className="info-wrapper">
                    <span className="name">Date Of Birth</span>
                    <Editable
                      text={data["DateOfBirth"]}
                      name="DateOfBirth"
                      handler={updateField}
                    />
                  </div>
                </div>
              </InvoiceSection>
            </div>
            <InvoiceSection
              style={{
                display: "flex",
                justifyContent: "end",
                border: `none`,
                margin: 0,
              }}
            >
              <DoxBtn
                text="SUBMIT"
                icon="check"
                className={isChanged ? "" : "disable"}
                handler={submitHandler}
                style={{
                  margin: "18px 5px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              />
            </InvoiceSection>
          </Sidebar>
        </>
      ) : (
        <WidgetsLoader />
      )}
    </DetailsWrapper>
  );
};

export default AdhaarDetails;
