import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "api";
import FileDownload from "js-file-download";

// styling
import {
  DetailsWrapper,
  SidebarWrapper,
  Content,
  InvoiceSection,
  Sidebar,
  Paging,
  TableSection,
} from "../style.js";

// material icons
import InfoIcon from "@mui/icons-material/Info";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Editable from "@ui/Dox/Editable/index.js";
import DoxBtn from "@ui/Dox/DoxBtn";
import WidgetsLoader from "@components/WidgetsLoader";
import { useAuth } from "@contexts/AuthProvider";
import { nanoid } from "nanoid";
import CanvasImage from "../CanvasImage.jsx";
import instance from "@utils/instance.js";
import DoxIconBtn from "@ui/Dox/DoxIconBtn/index.jsx";
import DownloadIcon from "@ui/Dox/DownloadIcon/index.jsx";
import { useInterfaceContext } from "@contexts/interfaceContext.js";
import EditableMultiline from "@ui/Dox/EditableMultiline/index.js";
import CustomSelectBox from "@ui/Dox/CustomSelectBox/index.jsx";

const PassportDetails = ({ state }) => {
  const [data, setData] = useState({});
  const [customSection, setCustomSection] = useState({
    info: "",
  });
  const [moderated, setModerated] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pagesLength, setPagesLength] = useState(1);
  const [pageDetails, setPageDetails] = useState([]);
  const [pageFields, setPageFields] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [fieldLabels, setFieldLabels] = useState([]);
  const [tableData, setTableData] = useState({});
  const [infoDetails, setInfoDetails] = useState([]);
  const [sellerDetails, setSellerDetails] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [othersDetails, setOthersDetails] = useState([]);
  const [tableDetails, setTableDetails] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [allLicenseLabels, setAllLicenseLabels] = useState([]);
  // const [newFields, setNewFields] = useState({});
  // const [isChanged, setIsChanged] = useState(false);
  // const [isShowField, setIsShowField] = useState(false);
  // const fieldNameRef = useRef("");
  // const fieldValueRef = useRef("");
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { notification, setNotification } = useInterfaceContext();
  const { payload, config, ext } = state;

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(500);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right -
            mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    // pointerEvents works for both mouse and touch
    window.addEventListener("pointermove", resize);
    window.addEventListener("pointerup", stopResizing);
    return () => {
      window.removeEventListener("pointermove", resize);
      window.removeEventListener("pointerup", stopResizing);
    };
  }, [resize, stopResizing]);

  const body = {
    companyId: payload.companyId,
    documentId: payload.documentId,
    documentType: payload.documentSubType,
  };

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const updateField = (e, id, isTable = false) => {
    let key = "";

    if (e.target.id === "info") {
      key = "info";
    }

    if (e.key === "Enter" || e.type === "blur") {
      let updatedFields = [];
      let updatedTableFields = [];
      let newFields = [];
      if (isTable) {
        const tableCells = tableData.cells.map((cell) =>
          cell.id === id ? { ...cell, text: e.target.value } : cell
        );
        updatedTableFields = {
          ...tableData,
          cells: tableCells,
        };
        setTableData(updatedTableFields);
      } else {
        if (allDetails) {
          updatedFields = allDetails.map((ele) =>
            ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
          );
        }
        setAllDetails(updatedFields);
        if (pageDetails && key !== "") {
          if (key === "info") {
            newFields = infoDetails.map((ele) =>
              ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
            );
          }
        }
      }
      if (isTable) {
        const allObj = allDetails.filter((ele) => ele.label !== "table");
        updatedFields = [...allObj, updatedTableFields];
      } else {
        const allObj = updatedFields.filter((ele) => ele.label !== "table");
        updatedFields = [...allObj];
      }
      const currentPageInfo = data.Pages.filter(
        (ele) => ele.pageNo === pageNumber
      )[0];
      currentPageInfo.moderated = updatedFields;

      if (key === "info") {
        setInfoDetails(newFields);
      }
      // get fields of all pages to updateDocuments as moderated
      const moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { id: page._id, moderated: page.moderated }
          : { id: page._id, moderated: page.prediction }
      );
      // setAllDetails(updatedFields)
      setPageFields(updatedFields);
      setModerated(moderatedFields);
      if (isTable) {
        setCurrentTableData(updatedTableFields);
      }
    }
  };

  const updateByBox = (e, obj, previousObj, isResized = false) => {
    const deleteObjectFromArray = (array, objectToDelete) => {
      const deletedArray = array.filter((obj) => obj !== objectToDelete);
      return deletedArray;
    };
    if (obj.label != previousObj.label) {
      setInfoDetails(deleteObjectFromArray(infoDetails, previousObj));
    }

    if (isResized && !pageFields.find((ele) => ele.id === obj.id)) {
      return;
    }

    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      const tableCells = tableData.cells.map((cell) =>
        cell.id === obj.id ? { ...cell, ...obj } : cell
      );
      const headerCells = tableData.tableHeaders.map((cell) =>
        cell.id === obj.id || (cell.col === cell.col && cell.row === "1")
          ? { ...cell, ...obj }
          : cell
      );
      updatedTableFields = {
        ...tableData,
        cells: tableCells,
        tableHeaders: headerCells,
      };
      // setTableData(updatedTableFields);
    } else if (obj.type || obj.isNew === true) {
      if (obj.isNew === true) {
        let newLabel = allDetails.some((item) => item.id === obj.id);
        if (!newLabel) {
          allLicenseLabels.map((item) => {
            if (item.key === obj.label) {
              if (item.group === "info") {
                obj = {
                  ...obj,
                  page_no: pageNumber,
                  key: item.key,
                };
                infoDetails.push(obj);
                setInfoDetails(infoDetails);
                allDetails.push(obj);
                updatedFields = allDetails;
                setAllDetails(updatedFields);
              }
            }
          });
        }
      }

      updatedFields = allDetails.map((ele) =>
        ele.id === obj.id ? { ...ele, ...obj } : ele
      );
      setAllDetails(updatedFields);
      if (pageFields) {
        allLicenseLabels.map((item) => {
          if (item.key === obj.label) {
            //function for update and switch group label
            const updateGroupLabel = (arr, setGroup) => {
              const ind = arr.findIndex((item) => item.id === obj.id);
              if (ind !== -1) {
                const updatedDetails = [...arr];
                updatedDetails[ind] = obj;
                setGroup(updatedDetails);
              } else {
                const updatedArr = [...arr, obj];
                setGroup(updatedArr);
              }
            };

            if (item.group === "info") {
              updateGroupLabel(infoDetails, setInfoDetails);
            }
          }
        });
        
        // const updatedInfoDetails = infoDetails.map((ele) =>
        //   ele.id === obj.id ? { ...ele, ...obj } : ele
        // );

        // setInfoDetails(updatedInfoDetails);
      }
    } else {
      updatedFields = [
        ...pageFields,
        { ...obj, page_no: pageNumber, type: "field" },
      ];
    }
    if (obj.row) {
      const allObj = allDetails.filter((ele) => ele.label !== "table");
      updatedFields = [...allObj, updatedTableFields];
    } else {
      const allObj = updatedFields.filter((ele) => ele.label !== "table");
      updatedFields = [...allObj];
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedTableFields);
    }
  };

  const deleteByBox = (e, obj) => {
    // if (!pageFields.find((ele) => ele.id === obj.id)) {
    //   return;
    // }
    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      // const tableCells = tableData.cells.filter((cell) => cell.id !== obj.id);

      updatedTableFields = {
        ...tableData,
        // cells: tableCells,
      };
    } else if (obj.type || obj.isNew === true) {
      updatedFields = allDetails.filter((ele) => ele.id !== obj.id);
      setAllDetails(updatedFields);
      if (pageFields) {
        const updatedInfoDetails = infoDetails.filter(
          (ele) => ele.id !== obj.id
        );
        setInfoDetails(updatedInfoDetails);
      }
    }

    if (obj.row) {
      const allObj = allDetails.filter(
        (ele) => ele.label !== "table" && ele.id !== obj.id
      );
      updatedFields = [...allObj, updatedTableFields];
    } else {
      const allObj = updatedFields.filter((ele) => ele.label !== "table");
      updatedFields = [...allObj];
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedTableFields);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const submitHandler = async () => {
    const obj = {
      _id: data._id,
      documentType: body.documentType,
      moderatedFields: moderated,
    };
    if (moderated.length === 0) {
      // get fields of all pages to updateDocuments as moderated
      data.Pages.map((page) => {
        if (page.moderated.length > 0) {
          obj.moderatedFields = { id: page._id, moderated: page.moderated };
        } else {
          obj.moderatedFields = [{ id: page._id, moderated: allDetails }];
        }
      });
    }

    try {
      setIsLoading(true);
      const res = await api.post(
        "/document/updateDocumentData",
        { ...obj },
        {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        }
      );
      // console.log(res)
      // set notification for new file uploaded
      const oldNotify = notification.isMarkAsRead
        ? notification.notifications.slice(0, 5)
        : notification.notifications;
      const notifications = [
        {
          id: nanoid(),
          type: body.documentType,
          msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
          tab: "Reviewed",
        },
        ...oldNotify,
      ];

      setNotification({
        isMarkAsRead: false,
        notifications: notifications,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
    goBack();
  };

  const getDetails = async () => {
    let fileInfo;
    try {
      setIsLoading(true);
      const res = await instance.post(
        "/document/showDocumentDetails",
        body,
        config
      );

      fileInfo = res.data;
      const allLabels = res.data.passportLabels;
      const pageData = res.data.result;
      const allFields = pageData.Pages[0].prediction;
      if (allFields) {
        let infoSection = "";

        allFields.map((field) => {
          if (field.hasOwnProperty("info")) {
            infoSection = field.info.groupVal;
          }
        });
        setCustomSection({
          info: infoSection,
        });
      }

      setData(pageData);
      setAllLicenseLabels(allLabels);
      setPagesLength(pageData.Pages.length);
      getCurrentPageDetails(pageData);
      getHeaders();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getHeaders = async () => {
    try {
      setIsLoading(true);
      const res = await instance.get("/dashboard/getLabels", {}, config);
      const headerLabels = res.data.data.passportTables;
      const feildLabels = res.data.data.passportLabels;
      setFieldLabels(feildLabels);
      setTableHeaders(headerLabels);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  // get csv file
  const getCsv = async () => {
    const URL = `${process.env.REACT_APP_BASE_URL}/document/downloadExtractedCSV`;
    // download CSV api call
    instance({
      url: URL,
      method: "post",
      responseType: "blob",
      headers: { "x-access-token": auth.user.accessToken },
      data: {
        documentIds: [payload.documentId],
        documentType: payload.documentSubType,
      },
    })
      .then((resp) => {
        FileDownload(resp.data, "Extracted.csv");
      })
      .catch((e) => console.log(e));
  };

  const getCurrentPageDetails = (data) => {
    const page =
      data.Pages && data.Pages.filter((ele) => ele.pageNo === pageNumber)[0];
    setPageDetails(page);
    const fields =
      page && page.moderated.length > 0
        ? page && page.moderated
        : page && page.prediction;
    setPageFields(fields);
    if (fields) {
      fields.map((field) => {
        if (field.hasOwnProperty("info")) {
          setInfoDetails(field.info.data);
        }
      });
    }

    setCurrentTableData(fields);
  };

  useEffect(() => {
    if (tableDetails.length > 0) {
      setAllDetails([...infoDetails, tableDetails]);
    } else {
      setAllDetails([...infoDetails]);
    }
  }, [pageDetails]);

  const updateSectionHandler = async (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "info") {
        setCustomSection({
          ...customSection,
          info: e.target.value,
        });
      }

      try {
        const res = await instance.post(
          "/document/updateCustomSection",
          {
            LabelName: "passportLabels",
            currentGroupName: e.target.id,
            newGroupName: e.target.value,
          },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const changeHeaderHandler = (e) => {
    if (!auth.isAdmin()) {
      return;
    }
    const id = e.target.name;
    const selected = tableHeaders.filter(
      (ele) => ele.key === e.target.value
    )[0];

    const selectedCell = tableData.tableHeaders.filter(
      (ele) => ele.id === id
    )[0];

    let updatedFields = [];
    //   .filter((field) => field.label === "table")[0]
    const tableCells = tableData.cells.map((cell) =>
      cell.col === selectedCell.col
        ? { ...cell, aliasLabel: selected.value, label: selected.key }
        : cell
    );

    const headerCells = tableData.tableHeaders.map((cell) =>
      cell.col === selectedCell.col
        ? { ...cell, aliasLabel: selected.value, label: selected.key }
        : cell
    );
    updatedFields = {
      ...tableData,
      cells: tableCells,
      tableHeaders: headerCells,
    };

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    setCurrentTableData(updatedFields);
  };

  const setCurrentTableData = (fields) => {
    let tableInfo = "";
    if (fields && Array.isArray(fields)) {
      fields.map((field) => {
        if (field.hasOwnProperty("table")) {
          tableInfo = field.table.data[0];
        }
      });
    }

    const tableHeaders =
      tableInfo && tableInfo.cells.filter((cell) => cell.row === 1);
    // tableInfo && tableInfo.cells.filter((cell) => cell.row );
    if (Array.isArray(fields)) {
      setTableData({ ...tableInfo, tableHeaders: tableHeaders });
    } else {
      setTableData(fields);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    getCurrentPageDetails(data);
  }, [pageNumber]);

  const previousHandler = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextHandler = () => {
    if (pageNumber < pagesLength - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <DetailsWrapper>
      {!isLoading ? (
        <>
          <Content>
            <CanvasImage
              image={
                pageDetails &&
                pageDetails.images &&
                pageDetails.images.original_with_long_expiry
              }
              // image={pages.images.original && pages.images.original_with_long_expiry}
              info={[...infoDetails, tableData]}
              resizeHandler={updateByBox}
              fieldlabels={fieldLabels.length > 0 && fieldLabels}
              tableHeaders={tableHeaders}
              updateHandler={updateByBox}
              deleteHandler={deleteByBox}
            />
          </Content>
          {/* resizer class is enable resize bar  */}
          <SidebarWrapper ref={sidebarRef} style={{ width: sidebarWidth }}>
            <div className="resizer" onPointerDown={startResizing} />
            <Sidebar style={{ display: "flex" }}>
              <InvoiceSection className="paging-section">
                <Paging style={{ position: "unset", fontSize: "14px" }}>
                  <span
                    className={`prev ${pageNumber > 0 ? "primary" : "disable"}`}
                    onClick={previousHandler}
                  >
                    <ArrowLeftIcon />
                  </span>
                  <span>Page {pageNumber + 1}</span>
                  <span
                    className={`next ${
                      pageNumber < pagesLength - 1 ? "primary" : "disable"
                    }`}
                    onClick={nextHandler}
                  >
                    <ArrowRightIcon />
                  </span>
                </Paging>
                <DoxIconBtn
                  icon="close"
                  iconStyles={{ margin: "0px", padding: "0px" }}
                  handler={goBack}
                />
              </InvoiceSection>
              <InvoiceSection style={{ paddingTop: "15px", height: "100%" }}>
                <div className="title" style={{ justifyContent: "flex-end" }}>
                  {pageDetails.moderated &&
                    pageDetails.moderated.length > 0 && (
                      <DownloadIcon
                        className={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0
                            ? "download-csv"
                            : "disable"
                        }
                        handler={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0 &&
                          getCsv
                        }
                      />
                    )}
                </div>
                <form className="details">
                  {infoDetails.length > 0 && (
                    <div className="title">
                      <span
                        style={{
                          display: "inline-flex",
                          textTransform: "capitalize",
                        }}
                      >
                        <Editable
                          text={customSection.info}
                          id="info"
                          handler={updateSectionHandler}
                          style={{
                            width: "100%",
                          }}
                          editable={auth.isAdmin()}
                        />
                      </span>
                    </div>
                  )}

                  {infoDetails &&
                    infoDetails.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              text={ele.ocr_text}
                              id="info"
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}

                  {tableData.tableHeaders && (
                    <TableSection>
                      <div className="title">
                        <span
                          style={{ display: "inline-flex", marginLeft: "10px" }}
                        >
                          Table
                        </span>
                      </div>
                      <div
                        className="table"
                        style={{
                          gridTemplateColumns: `repeat(${
                            tableData.tableHeaders &&
                            tableData.tableHeaders.length
                          }, 1fr)`,
                        }}
                      >
                        {tableData.tableHeaders &&
                          tableData.tableHeaders.map(
                            (ele) =>
                              ele.row === 1 && (
                                <div className="th" key={ele.id}>
                                  <CustomSelectBox
                                    className="info"
                                    selected={ele}
                                    items={tableHeaders}
                                    handler={changeHeaderHandler}
                                  />
                                </div>
                              )
                          )}
                        {tableData.cells &&
                          tableData.cells.map((td) => (
                            <div className="td" key={td.id}>
                              <EditableMultiline
                                className="info"
                                text={td.text}
                                name={td.label}
                                handler={(e) => updateField(e, td.id, true)}
                                editable={auth.isAdmin()}
                              />
                            </div>
                          ))}
                      </div>
                    </TableSection>
                  )}
                </form>
              </InvoiceSection>

              <InvoiceSection
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: 0,
                }}
              >
                {auth.isAdmin() && (
                  <DoxBtn
                    text="submit"
                    icon="check"
                    handler={submitHandler}
                    style={{
                      margin: "18px 5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                )}
              </InvoiceSection>
            </Sidebar>
          </SidebarWrapper>
        </>
      ) : (
        <WidgetsLoader />
      )}
    </DetailsWrapper>
  );
};

export default PassportDetails;
