import axios from "axios";
// styled components
import {
  Container,
  FlexContainer,
  Content,
  LoginSideImage,
  StyledForm,
  LoginLink,
  LoginContainer,
} from "../style";

// components
import { useState, useEffect } from "react";
import Btn from "@ui/Btn";
import Message from "@ui/Message";
import LoginFormInput from "@ui/LoginFormInput";
import { toast } from "react-toastify";
import ForgetPassImg from "@assets/forget_pass.jpg";
import { useAuth } from "@contexts/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import logoLight from "@assets/logo/logo-light.png";
import Loader from "@ui/Dox/Loader";
import LoginBtn from "@ui/Btn/loginBtn";

const VerifyForgetPassword = () => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  const [serachParams] = useSearchParams();

  const id = serachParams.get("id");
  const token = serachParams.get("token");
  const salesmateContactId = serachParams.get("salesmateContactId") || null;
  const changeHandler = (e) => {
    setError("");
    // auth.setAuthError("");
    setPassword({ ...password, [e.target.id]: e.target.value });
    // console.log(e.target.value);
  };

  const forgetHandler = async () => {
    if (!password.first || !password.second) {
      // setError("Password can't be blank.");
      toast.error("Password can't be blank", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (password.first !== password.second) {
      // setError("Password mismatch.");
      toast.error("Password mismatched", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const payload = {
      userId: id,
      token: token,
      newPassword: password.first,
      salesmateContactId: salesmateContactId,
    };
    const baseURL = process.env.REACT_APP_BASE_URL;
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${baseURL}/auth/verifyforgetPassword`,
        payload
      );
      // setSuccess(res.data.msg);
      toast.success(`${res.data.msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (e) {
      if (e.response) {
        console.log(e.response);
      } else if (e.request) {
        console.log(e.request);
      } else {
        console.log(e);
      }
    } finally {
      setIsLoading(false);
    }
    // console.log(payload);
  };

  const roleNavigate = (role) => {
    navigate("/dashboard/home", { replace: true });
  };

  useEffect(() => {
    auth.setAuthError("");
    if (auth.user) {
      roleNavigate(auth.user.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Container>
      <Content>
        {/* <div className="wrapper"> */}
        {/* <FlexContainer className="left-image">
          <LoginSideImage src={ForgetPassImg} alt="image" />
        </FlexContainer> */}
        <div className="white-bg"></div>

        <FlexContainer>
          <LoginContainer>
          <img
              src={logoLight}
              alt="Docxster-logo"
              className="docxster_logo"
              style={{marginTop:'-20px'}}
            />
            <h1 style={{whiteSpace:'nowrap',fontSize:'28px'}}>Enter New Password</h1>
            <Message className="error">{error ? error : ""}</Message>

            {success ? (
              <Message className="success">{success}</Message>
            ) : (
              <StyledForm
                action="#"
                method="post"
                onSubmit={(e) => e.preventDefault()}
              >
                <LoginFormInput
                  id="first"
                  title="Password"
                  type="password"
                  placeholder="Enter new password"
                  value={password.first}
                  handler={changeHandler}
                />
                <LoginFormInput
                  id="second"
                  title="Password"
                  type="password"
                  placeholder="Re-Enter new password"
                  value={password.second}
                  handler={changeHandler}
                />
                <LoginBtn
                  text={!isLoading ? "Continue" : <Loader />}
                  handler={forgetHandler}
                  type="submit"
                />
              </StyledForm>
            )}
          </LoginContainer>
          <LoginLink to="/auth/login">Login?</LoginLink>
        </FlexContainer>
        {/* </div> */}
      </Content>
    </Container>
  );
};

export default VerifyForgetPassword;
