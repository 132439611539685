import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const CustomSelectBox = ({ selected, items, className, handler }) => {
  return (
    <FormControl style={{ width: "100%" }}>
      {items.length > 0 && (
        <Select
          className={className}
          value={(selected?.aliasLabel && selected.aliasLabel) || ""}
          defaultValue=""
          name={selected.id}
          onChange={handler}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
          }}
          style={{
            // width: "100%",
            width: "130px",
            fontWeight: "500",
            textTransform: "capitalize",
            height: "50px",
            textAlign: "center",
          }}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {items.length > 0 &&
            items.map((item) => (
              <MenuItem value={item.value} key={item.key}>
                {item.value}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
};

export default CustomSelectBox;
