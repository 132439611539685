// styled components
import {
  Actions,
  Header,
  Wrapper,
  ContentTitle,
  MenuNotify,
  UserWrapper,
} from "./panelStyle";

// components
import { useState, useRef, useEffect } from "react";
import MenuButton from "@ui/MenuButton";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import ShapeButton from "@ui/Dox/ShapeButton";
import { motion } from "framer-motion";
import CurrentUser from "@layout/Panel/CurrentUser";
import logo from "@assets/docxster_logo.svg";

// hooks
import useWindowSize from "@hooks/useWindowSize";
import usePanelScroll from "@hooks/usePanelScroll";
import { useSidebarContext } from "@contexts/sidebarContext";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useNavigate, useLocation } from "react-router-dom";
import ThemeToggleSwitch from "@ui/Dox/ThemeToggleBtn";
import { useAuth } from "@contexts/AuthProvider";

const Panel = () => {
  const { width } = useWindowSize();
  // const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState("");
  const isMobile = width < 768;
  // const isDesktop = width >= 1366;
  const classname = usePanelScroll();
  const { isSidebarOpen } = useSidebarContext();
  // eslint-disable-next-line
  const { pageTitle, setIsUploadPopUpShow, notification, setNotification } =
    useInterfaceContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [docTypeRoute, setDocTypeRoute] = useState(false);
  const [notificationPresent, setNotificationPresent] = useState(false);

  const handleClickAway = () => setOpen(false);

  const handleClick = () => {
    setNotification({ ...notification, isMarkAsRead: true });
    setOpen(!open);
  };
  // const { isUploadPopUpShow, setIsUploadPopUpShow } = useInterfaceContext();
  const headerRef = useRef(null);

  const auth = useAuth();

  const showDocuments = (ele) => {
    const documentType = `/dashboard/documents/${ele.type}?tab=${ele.tab}`;
    handleClickAway();
    navigate(documentType);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerRef.current.offsetHeight}px`
    );
  }, [width]);

  useEffect(() => {
    // console.log("panel", notification);
  }, [notification]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRoute(selectedValue);
    // Perform navigation based on the selected value
    switch (selectedValue) {
      case "invoice":
        navigate("/dashboard/documents/Invoice?tab=In+Review");
        break;
      case "bol":
        navigate("/dashboard/documents/BillOfLading?tab=In+Review");
        break;
      case "po":
        navigate("/dashboard/documents/PurchaseOrder?tab=In+Review");
        break;
      case "passport":
        navigate("/dashboard/documents/Passport?tab=In+Review");
        break;
      case "dl":
        navigate("/dashboard/documents/DrivingLicense?tab=In+Review");
        break;
      case "bs":
        navigate("/dashboard/documents/BankStatement?tab=In+Review");
        break;
      default:
        // navigate("/dashboard/documents/Invoice?tab=In+Review");
        break;
    }
  };

  const location = useLocation();

  useEffect(() => {
    const pathOption = {
      Invoice: "invoice",
      BillOfLading: "bol",
      PurchaseOrder: "po",
      Passport: "passport",
      DrivingLicense: "dl",
      BankStatement: "bs",
    };
    // console.log(location);
    const locArr = location?.pathname?.split("/");
    const route = pathOption[locArr[locArr.length - 1]];
    setSelectedRoute(route);
  }, [location]);

  useEffect(() => {
    const currentRoute = location.pathname;
    const segments = currentRoute.split("/");
    segments.pop();

    const updatedRoute = segments.join("/");
    if (updatedRoute === "/dashboard/documents") {
      setDocTypeRoute(true);
    } else {
      setDocTypeRoute(false);
    }
  }, [location]);

  useEffect(() => {
    if (pageTitle === "Invoices" && selectedRoute !== "invoice") {
      setSelectedRoute(pageTitle);
    }
  }, [pageTitle]);

  useEffect(() => {
    const present = notification.notifications.some(
      (ele) => auth?.user?.email === ele?.user
    );
    setNotificationPresent(present);
  }, [auth, notification]);
  return (
    <Header
      as={motion.header}
      animate={{ y: isSidebarOpen && isMobile ? "-100%" : 0 }}
      transition={{ duration: 0.3, ease: "linear", type: "tween" }}
      className={classname}
      ref={headerRef}
    >
      <Wrapper
        as={motion.div}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
        // style={{ flex: "1" }}
      >
        {width < 768 && (
          <img
            src={logo}
            alt="logo"
            // style={{ height: "84px", width: "72px", marginRight: "10px" }}
          />
        )}
        {/* <img
          src={logo}
          alt="logo"
          // style={{ height: "84px", width: "72px", marginRight: "10px" }}
        /> */}
        <div
          style={{
            padding: "0 15px",
            borderLeft: width < 768 && "2px solid rgb(185 182 182 / 45%)",
          }}
        >
          {pageTitle && <ContentTitle> {pageTitle}</ContentTitle>}
          {docTypeRoute && (
            <select
              className="select"
              value={selectedRoute}
              onChange={handleChange}
            >
              <option value="invoice">Invoice</option>
              <option value="bol">Bill Of Lading</option>
              <option value="po">Purchase Order</option>
              <option value="passport">Passport</option>
              <option value="dl">Driving License</option>
              {/* <option value="bs">Bank Statement</option> */}
            </select>
          )}
        </div>
      </Wrapper>

      <Actions>
        {width > 767 && <ThemeToggleSwitch />}

        <ClickAwayListener onClickAway={handleClickAway}>
          <UserWrapper style={{ margin: "0px", gap: "0px" }}>
            <ShapeButton
              shape={`squares ${open ? "active" : ""}`}
              label="Notification"
              icon="bell"
              hasNotification={
                notification.notifications.length > 0 &&
                !notification.isMarkAsRead
              }
              handler={handleClick}
            />
            <div className="info">
              <MenuNotify className={open ? "visible" : ""}>
                {notificationPresent ? (
                  notification.notifications.map(
                    (ele) =>
                      auth?.user?.email === ele?.user && (
                        <span
                          key={ele.id}
                          onClick={() => showDocuments(ele)}
                          className="item"
                          style={{ cursor: "pointer" }}
                        >
                          {ele.msg}
                        </span>
                      )
                  )
                ) : (
                  <span className="item">No notification available</span>
                )}
              </MenuNotify>
            </div>
          </UserWrapper>
        </ClickAwayListener>

        {/* {isDesktop ? <CurrentUser /> : <ShapeButton shape="square" label="Profile" icon="user" />} */}

        {width > 767 && <CurrentUser />}

        {/* {width < 1280 && <MenuButton />} */}
        {width < 768 && <MenuButton />}
      </Actions>
    </Header>
  );
};

export default Panel;
